<nz-card *ngIf="showTable">
    <div class="row  m-b-30">
        <!-- <div class="col-lg-8 heading-section">
            <div class="m-b-10 m-r-20">
                <nz-input-group [nzPrefix]="prefixTemplate">
                    <input type="text" nz-input placeholder="Search Project" [(ngModel)]="searchInput">
                </nz-input-group>
                <ng-template #prefixTemplate>
                    <i nz-icon nzType="search" class="opacity-05"></i>
                </ng-template>
            </div>
            <div class="m-b-10 m-r-20 d-flex align-items-center">
                <nz-select [(ngModel)]="selectedTable" style="min-width: 220px;" class="w-100" nzPlaceHolder="All Products">
                    <nz-option nzLabel="All Products" nzValue="All Products"></nz-option>
                    <nz-option nzLabel="All Categories" nzValue="All Categories"></nz-option>
                </nz-select>
            </div>
            <div class="Offers-filter">
                <label class="mr-3" for="">Offers : </label>
                <nz-switch [(ngModel)]="ShowOffer"></nz-switch>
            </div>
        </div> -->

        <div class="col-lg-10">
            <div class="d-md-flex">
                <div class="m-b-10 m-r-20">
                    <nz-input-group [nzPrefix]="prefixTemplate">
                        <input type="text" nz-input placeholder="Search Project" [(ngModel)]="searchInput">
                    </nz-input-group>
                    <ng-template #prefixTemplate>
                        <i nz-icon nzType="search" class="opacity-05"></i>
                    </ng-template>
                </div>
                <div class="m-b-10 m-r-20 d-flex align-items-center">
                    <nz-select [(ngModel)]="selectedTable" style="min-width: 220px;" class="w-100" nzPlaceHolder="All Products">
                        <nz-option nzLabel="All Products" nzValue="All Products"></nz-option>
                        <nz-option nzLabel="All Categories" nzValue="All Categories"></nz-option>
                    </nz-select>
                </div>
                <div class="m-b-10 m-r-20 d-flex align-items-center" style="padding-left: 5px;">
                    <label class="mr-3" for="">Offers : </label>
                    <nz-switch [(ngModel)]="ShowOffer"></nz-switch>
                </div>
            </div>
        </div>


    </div>

    <!-- ALL PRODUCTS TABLE -->

    <nz-table *ngIf="selectedTable == 'All Products'" #productsListTable [nzData]="productsList">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of productsListTable.data;  let i=index">
                <td>{{i+1}}</td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.skuID}}
                    </div>
                </td>
                <td>{{item.name}}</td>
                <td>
                    <nz-avatar nzShape="square" [nzSize]="80" nzIcon="picture" [nzSrc]="item.image"></nz-avatar>
                </td>
                <td>{{item.brand}}</td>
                <!-- <td>
                    <nz-badge *ngIf="item.featured == 'featured'" nzStatus="success"></nz-badge>
                    <nz-badge *ngIf="item.featured == 'not featured'" nzStatus="error"></nz-badge>
                    <span class="text-capitalize">{{item.featured}}</span>
                </td> -->

                <td>{{item.model}}</td>
                <td>Rs. {{item.regularPrice}}</td>
                <td>Rs. {{item.salePrice}}</td>
                <td>{{item.category}}</td>

                <td class="">
                    <!-- <a (click)="addOffer()" class="m-r-5" nz-button nzType="default" nzShape="circle" nz-tooltip nzTooltipTitle="Edit">
                        <i nz-icon nzType="edit" theme="outline"></i>
                    </a> -->
                    <button (click)="addOffer()" nz-button>{{!ShowOffer? 'Add to' : 'Update'}} Offer</button>
                </td>
            </tr>
        </tbody>
    </nz-table>

    <!-- ALL CATEGORIES TABLE -->

    <nz-table *ngIf="selectedTable == 'All Categories'" #CategoryListTable [nzData]="CategoryList">
        <thead>
            <tr>
                <th *ngFor="let column of CategoryHeading" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of CategoryListTable.data;  let i=index">
                <td>{{i+1}}</td>

                <td>{{item.name}}</td>

                <td>
                    <nz-avatar nzShape="square" [nzSize]="80" nzIcon="picture" [nzSrc]="item.image"></nz-avatar>
                </td>

                <td>
                    <nz-avatar nzShape="square" [nzSize]="80" nzIcon="picture" [nzSrc]="item.banner"></nz-avatar>
                </td>

                <td>
                    <nz-badge *ngIf="item.featured == 'featured'" nzStatus="success"></nz-badge>
                    <nz-badge *ngIf="item.featured == 'not featured'" nzStatus="error"></nz-badge>
                    <span class="text-capitalize">{{item.featured}}</span>
                </td>

                <td>{{item.specification}}</td>

                <td class="">
                    <button (click)="addOffer()" nz-button>{{!ShowOffer? 'Add to' : 'Update'}} Offer</button>
                    <!-- <button *ngIf="ShowOffer == true" (click)="addOffer()" nz-button>Update Offer</button> -->
                </td>
            </tr>
        </tbody>
    </nz-table>

    <!-- ALL OFFERS TABLE -->

    <!-- <nz-table *ngIf="selectedTable == 'All Offers'" #OfferListTable [nzData]="OfferList">
        <thead>
            <tr>
                <th *ngFor="let column of OfferHeading" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of OfferListTable.data;  let i=index">
                <td>{{i+1}}</td>

                <td>{{item.skuID}}</td>

                <td>{{item.name}}</td>

                <td>
                    <nz-avatar nzShape="square" [nzSize]="80" nzIcon="picture" [nzSrc]="item.banner"></nz-avatar>
                </td>

                <td>Rs. {{item.regularPrice}}</td>

                <td>Rs. {{item.discount}}</td>

                <td>Rs. {{item.offerPrice}}</td>

                <td class="">
                    <a (click)="addOffer()" class="m-r-5" nz-button nzType="default" nzShape="circle" nz-tooltip nzTooltipTitle="Edit">
                        <i nz-icon nzType="edit" theme="outline"></i>
                    </a>
                </td>
            </tr>
        </tbody>
    </nz-table> -->

</nz-card>