import { SideNavInterface } from '../../interfaces/side-nav.type';
export const ROUTES: SideNavInterface[] = [
  {
    path: 'dashboard',
    title: 'Dashboard',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'dashboard',
    submenu: [],
  },
  {
    path: '/attribute',
    title: 'Attributes',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'solution',
    submenu: [],
  },
  // {
  //   path: '/recent-orders',
  //   title: 'Order List',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   icon: 'solution',
  //   submenu: [],
  // },
  // {
  //   path: '',
  //   title: 'Specification',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   icon: 'folder',
  //   submenu: [
  //     {
  //       path: '/specGroup',
  //       title: 'Manage Group',
  //       iconType: 'nzIcon',
  //       iconTheme: 'outline',
  //       icon: 'group',
  //       submenu: [],
  //     },
  //     {
  //       path: '/specValue',
  //       title: 'Manage Value',
  //       iconType: 'nzIcon',
  //       iconTheme: 'outline',
  //       icon: 'reconciliation',
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '/brands',
  //   title: 'Brands',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   icon: 'tags',
  //   submenu: [],
  // },
  {
    path: '/category',
    title: 'Category',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'database',
    submenu: [],
  },
  // {
  //   path: '/offer-page',
  //   title: 'Offer',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   icon: 'percentage',
  //   submenu: [],
  // },
  {
    path: '/media',
    title: 'Media',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'file-image',
    submenu: [],
  },
  {
    path: '/products',
    title: 'Products',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'book',
    submenu: [],
  },
  {
    path: '/report',
    title: 'Wishlist',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'audit',
    submenu: [],
  },
  {
    path: '/whatsapp-shared',
    title: 'Whatsapp Shares',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'whats-app',
    submenu: [],
  },
  // {
  //   path: '/banners',
  //   title: 'Banners',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   icon: 'picture',
  //   submenu: [],
  // },
  // {
  //   path: '/user-management',
  //   title: 'User Management',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   icon: 'solution',
  //   submenu: [],
  // },
  // {
  //   path: '/setting',
  //   title: 'Setting',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   icon: 'setting',
  //   submenu: [],
  // },
];
