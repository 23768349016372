<div class="container">
    <div class="row">
        <div class="col-10">
            <div class="text-center mb-5">
                <h2>Add New Service Center</h2>
            </div>
            <form nz-form>
                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ServiceName">Name</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text " nz-input id="ServiceName " placeholder="Enter Service Center Name ">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4 " nzRequired nzFor="ServiceAddress1 ">Address Line 1</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text " nz-input id="ServiceAddress1 " placeholder=" ">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4 " nzRequired nzFor="ServiceAddress2 ">Address Line 2</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text " nz-input id="ServiceAddress2 " placeholder=" ">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ServiceCity">City</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text " nz-input id="ServiceCity " placeholder="Enter Service Center Name ">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ServiceState">State</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text " nz-input id="ServiceState " placeholder="Enter Service Center Name ">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ServicePincode">Pincode</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text " nz-input id="ServicePincode " placeholder="Enter Service Center Name ">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ServiceLocation">Location</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text " nz-input id="ServiceLocation " placeholder="Enter Service Center Name ">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ServiceMobile">Mobile Number</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text " nz-input id="ServiceMobile " placeholder="Enter Service Center Name ">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ServiceLandline">Landline Number</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text " nz-input id="ServiceLandline " placeholder="Enter Service Center Name ">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ServiceLandline">Description</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <textarea id="ServiceLandline" nz-input placeholder="Enter Your Text Here" [nzAutosize]="{ minRows: 3, maxRows: 6 }"></textarea>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ServiceWebsite">Website</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text " nz-input id="ServiceWebsite " placeholder="Enter Service Center Name ">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ServiceActive">Active</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-switch id="ServiceActive" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>


                <nz-form-item class="text-center">
                    <nz-form-control>
                        <button class="mr-5 " nz-button nzType="default">Reset</button>
                        <a routerLink="/attribute"><button nz-button nzType="primary">Add</button></a>
                    </nz-form-control>
                </nz-form-item>

            </form>

        </div>
    </div>
</div>