import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { scrollToTop } from '../../shared/dummy';
import axios, { Axios } from 'axios';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';

interface DataItem {
  productId: any;
  Skuld: number;
  name: string;
  type: any;
  image: any;
  bestseller: any;
  featured: any;
  active: any;
  brand: number;
  modelNumber: any;
  regularPrice: any;
  salePrice: any;
  tags: any;
  category: any;
  specification: any;
  color1:any;
  color2:any;
  attributeArray:any
  images:any
  attributeType:string
}

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
})
export class ProductsComponent implements OnInit {
  selectedCategory: string;
  selectedStatus: string;
  searchInput: any;
  displayData = [];
  reqAttrArray = [];

  showTable: boolean = true;
  showForm: boolean = false;
  currentIndex : any

  MediaVisible = false;
  AttributeMediaVisible = false;
  isVisible = false;
  isOkLoading = false;
  selectedValueAttribute : any

  currentUrl = '';

  validateForm: FormGroup;

  productType = 'simple';
  productName = '';
  productSkuID = '';
  productImages = [];
  productTags = [];
  categoryList = [];
  selectedBrand = '';
  modelNo = '';
  regularprice = '';
  sellPrice = '';
  isBestSeller = false;
  isFeatured = false;
  isActive = false;
  productShortDescription = '';
  productLongDescription = '';
  allowedAttributes = [];
  variations = [];
  brandList = [];
  listOfCategory = [];
  listOfBrands = [];
  selectedAttribute = [];
  sizeChart = [];
  selectedSize = [];
  SelectedColor = [];
  reqList : any = [];
  color1 : '';
  color2 : '';
  reqImageArray : any[] = [];
  defaultMode = 'Add';
  productId : any;
  resImage : any[] = []
  confirmAlert = false;
  AttributeType = 'image';

  color = '#00f533'

  orderColumn = [
    {
      width:'50px',
      title: 'S.NO',
    },
    {
      width:'100px',
      title: 'SKU ID',
      compare: (a: DataItem, b: DataItem) => a.Skuld - b.Skuld,
    },
    {
      width:'80px',
      title: 'Name',
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
    },
    {
      width:'80px',
      title: 'Type',
      compare: (a: DataItem, b: DataItem) => a.type - b.type,
    },
    {
      width:'80px',
      title: 'Image',
      compare: (a: DataItem, b: DataItem) => a.image - b.image,
    },
    {
      width:'150px',
      title: 'Best Seller',
      compare: (a: DataItem, b: DataItem) => a.bestseller - b.bestseller,
    },
    {
      width:'100px',
      title: 'Featured',
      compare: (a: DataItem, b: DataItem) => a.featured - b.featured,
    },
    {
      width:'80px',
      title: 'Active',
      compare: (a: DataItem, b: DataItem) => a.active - b.active,
    },
    // {
      // width:'',
    //   title: 'Brand',
    //   compare: (a: DataItem, b: DataItem) => a.brand - b.brand,
    // },
    // {
      // width:'',
    //   title: 'Model Number',
    //   compare: (a: DataItem, b: DataItem) =>
    //     a.modelNumber.localeCompare(b.modelNumber),
    // },
    {
      width:'200px',
      title: 'Regular Price',
      compare: (a: DataItem, b: DataItem) => a.regularPrice - b.regularPrice,
    },
    {
      width:'180px',
      title: 'Sale Price',
      compare: (a: DataItem, b: DataItem) => a.salePrice - b.salePrice,
    },
    // {
      // width:'',
    //   title: 'Tags',
    //   compare: (a: DataItem, b: DataItem) => a.tags - b.tags,
    // },
    {
      width:'100px',
      title: 'Category',
      compare: (a: DataItem, b: DataItem) => a.category - b.category,
    },
    // {
      // width:'',
    //   title: 'Specification',
    //   compare: (a: DataItem, b: DataItem) => a.specification - b.specification,
    // },
    {
      width:'80px',
      title: 'Action',
    },
    // {
    //   title: 'Offer',
    // },
  ];

  productsList: DataItem[] = [];
  attributeList = [];
  //   {
  //     Skuld: '7586',
  //     name: 'intel core 17',
  //     type: 'simple',
  //     image: 'assets/images/others/thumb-12.jpg',
  //     bestseller: 'yes',
  //     featured: 'featured',
  //     active: 'inactive',
  //     brand: 'intel',
  //     modelNumber: 'Bksak58',
  //     regularPrice: '41299',
  //     salePrice: '36499',
  //     tags: 'i7-12 gen',
  //     category: 'processor',
  //     specification: 'processor specification',
  //   },
  //   {
  //     Skuld: '7586',
  //     name: 'intel core 17',
  //     type: 'simple',
  //     image: 'assets/images/others/thumb-10.jpg',
  //     bestseller: 'yes',
  //     featured: 'not featured',
  //     active: 'active',
  //     brand: 'intel',
  //     modelNumber: 'Bksak58',
  //     regularPrice: '41299',
  //     salePrice: '36499',
  //     tags: 'i7-12 gen',
  //     category: 'processor',
  //     specification: 'processor specification',
  //   },
  // ]; 

  // FORM DETAILS STARTS

  VariationHeading = [
    { heading: 'Variation' },
    { heading: 'SKU' },
    { heading: 'Regular Price' },
    { heading: 'Sale Price' },
    // { heading: 'Photo' },
  ];

  VariationData = [
    {
      Variation: 'AMD',
      RegularPrice: '41500',
      SKU: '54795',
      SalePrice: '35200',
      Photo: '',
    },
    {
      Variation: 'AMD',
      RegularPrice: '41500',
      SKU: '54795',
      SalePrice: '35200',
      Photo: '',
    },
  ];

  specificationGroup = [
    {
      specGroupName: 'Processor Specification',
    },
    {
      specGroupName: 'Keyboard Specification',
    },
    {
      specGroupName: 'Monitor Specification',
    },
    {
      specGroupName: 'Board Specification',
    },
  ];

  // FORM DETAILS ENDS

  // constructor(private tableSvc : TableService) {
  //     this.displayData = this.productsList
  // }

  // search(): void {
  //     const data = this.productsList
  //     this.displayData = this.tableSvc.search(this.searchInput, data )
  // }
  constructor(
    private fb: FormBuilder,
    public navService: NavService,
    private router: Router,
    private modalService: NzModalService
  ) {}

  fetchAttributes() {
    axios
      .get('https://seematti-backend.getdesignworks.com/attributes', {})
      .then((response) => {
        console.log(response.data.data);
        this.attributeList = response.data.data;
        for (let index = 0; index < response.data.data.length; index++) {
          if (response.data.data[index]._id == 'size') {
            this.sizeChart = response.data.data[index].values;
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        //alert(error.response.data.message);
      });
  }

  fetchData() {
    this.showForm = false;
    this.showTable = true;
    axios
      .get('https://seematti-backend.getdesignworks.com/products', {})
      .then((response) => {
        console.log(response.data.data);
        var initialResult = response.data.data;
        this.listOfCategory = initialResult.categoriesForDropdown;
        this.productsList = initialResult.products.map(
          (data: any, index: number) => {
            return {
              productId : data._id,
              Skuld: data.skuId,
              name: data.name,
              type: data.type,
              image: data.images[0].image.url,

              bestseller: data.isBestSeller,
              featured: data.isFeaturedProduct,
              active: data.isActive,
              // brand : data.brand.name,
              // modelNumber : data.modelNo,
              regularPrice: data.type == 'simple' ? data.regularPrice : data.variations[0].regularPrice,
              salePrice:data.type == 'simple' ? data.salePrice : data.variations[0].salePrice,
              // tags : data.tags,
              category: data.category,
              specification: 'spec group', //data.specifications[0].specGroup.name
              color1: data.bgGradient.color1,
              color2: data.bgGradient.color2,
              attributeArray : data.variations,
              images : data.images,
              attributeType : data.attributeType
            };
          }
        );
        console.log(this.productsList);

        this.fetchAttributes();

        //console.log(response.data.status);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  categoryChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.name === value))
    this.displayData = data;
  }

  statusChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.feature === value))
    //   : (this.displayData = data);
    this.displayData = data;
  }

  ngOnInit(): void {
    scrollToTop();
    this.fetchData();
    this.selectedTab = 'Processor Specification';
    this.currentUrl = this.router.url;

    this.validateForm = this.fb.group({});
  }

  AddOffer() {
    this.router.navigate([
      '/add-offer',
      { state: JSON.stringify(this.currentUrl) },
    ]);
  }

  showFormDetails() {
    this.resettingForm();
    this.showTable = !this.showTable;
    this.showForm = !this.showForm;
  }

  // POPOVER CLOSE BUTTON
  showModal(): void {
    this.isVisible = true;
  }
  showMedia() {
    this.MediaVisible = true;
  }

  // handleOk(): void {
  //   this.isOkLoading = true;
  //   setTimeout(() => {
  //     this.isVisible = false;
  //     this.isOkLoading = false;
  //   }, 3000);
  // }

  handleCancel(): void {
    this.isVisible = false;
    this.MediaVisible = false;
    this.AttributeMediaVisible = false;
  }
  selectedTab: string = '';
  ShowSpecValues(specSelectedValue: string) {
    this.selectedTab = specSelectedValue;
  }

  // FORM RESET SECTION

  resetForm(): void {
    this.validateForm.reset();
  }
  sendMedia() {
    this.productImages = [];
    this.reqImageArray = [];
    this.navService.formRefreshAnnouncedSource.subscribe((item) => {
      this.productImages = item;
      console.log('media images');
      console.log(this.productImages[0].image.url);
      for(var i = 0; i < this.productImages.length; i++){
        this.reqImageArray.push(this.productImages[i]._id)
      }
    });
    this.handleCancel();
  }

  doChanges() {
    var self = this;
    if (self.productName == '') {
      alert('Enter product Name');
    } else if (self.productSkuID == '') {
      alert('Enter Product SkuId');
    } else if (self.productImages.length == 0) {
      alert('Select Product Images');
    }
    // else if(self.productTags.length == 0){
    //   alert('Select Product Tags');
    // }
    else if (self.categoryList == undefined) {
      alert('Select Category');
    }
    // else if(self.selectedBrand == ''){
    //   alert('Select Brand');
    // }
    // else if(self.modelNo == ''){
    //   alert('Enter Model Number');
    // }
    // else if (this.regularprice == '') {
    //   alert('Enter Regular Price');
    // } else if (this.sellPrice == '') {
    //   alert('Enter Sell Price');
    // } 
    else if(self.color1.length == 0){
      alert('Select the gradient color1 ');
    } else if(self.color2.length == 0){
      alert('select the gradient color2')
    } 
    else if(self.reqAttrArray.length == 0){
      alert('Select Attributes with variants');
    }
    // else if(this.productTags.length == 0){
    //   alert('Enter Product Tags');
    // }
    else {
      console.log(self.reqAttrArray);
      // console.log(this.reqList);
      var theReqArray : any = [];
      for(var i = 0; i < self.reqAttrArray.length; i++){
        var requestObj : any = {};
        var attributesArray : any = []; 
        requestObj.label = self.reqAttrArray[i].label1;
        requestObj.value = self.reqAttrArray[i].combination.split('-')[0].trim();
        // if((this.reqAttrArray[i].combination.split('-').length == 2) && (i == 0 || i % 2 == 0)){
         attributesArray.push(requestObj);
        // } else if ((this.reqAttrArray[i].combination.split.length == 3) && (i == 0 || i % 3 == 0)){
        //   attributesArray.push(requestObj);
        // }
        requestObj = {};
        requestObj.label = self.reqAttrArray[i].label2;
        requestObj.value = self.reqAttrArray[i].combination.split('-')[1].trim();
        // if((this.reqAttrArray[i].combination.split('-').length == 2) && (i == 0 || i % 2 == 0)){
           attributesArray.push(requestObj);
        // } else if ((this.reqAttrArray[i].combination.split.length == 3) && (i == 0 || i % 3 == 0)){
        //   attributesArray.push(requestObj);
        // }
        //theReqArray.push(attributesArray);
        var finalObj : any = {};
        finalObj.attributes = attributesArray
        finalObj.attributes = attributesArray;
        finalObj.regularPrice = self.reqAttrArray[i].regularPrice;
        finalObj.salePrice = self.reqAttrArray[i].salePrice;
        finalObj.skuId = self.reqAttrArray[i].skuId;
        finalObj.imageId = self.reqAttrArray[i].imageId;
        if(self.reqAttrArray[i].colorCode != undefined)
        finalObj.colorCode = self.reqAttrArray[i].colorCode
        theReqArray.push(finalObj);
      }
     
        console.log('after');
        console.log(theReqArray);
      var reqObj = {};
     // var self = this;
      console.log(self.categoryList);
      //console.log(JSON.parse(self.categoryList));
      var categorylist = [];
      categorylist.push(self.categoryList);
      for(var i = 0; i < self.reqImageArray.length; i++){
        for(var j = 0; j < self.reqAttrArray.length; j++){
          if(self.reqImageArray[i] == self.reqAttrArray[j].imageId){
            self.reqImageArray.splice(i,1);
          }
        }
      }
      if(self.defaultMode == 'Add'){
      var productData = {
        skuId: self.productSkuID,
        name: self.productName,
        images: self.reqImageArray,
        type: self.productType,
        //allowedAttributes: [],
        bgGradient: {
          color1 : self.color1,
          color2 : self.color2,
      },
        
        variations: theReqArray,
        // regularPrice: this.regularprice,
        // salePrice: this.sellPrice,
        // "tags": self.productTags,
        category: self.categoryList,
        isBestSeller: self.isBestSeller,
        isFeaturedProduct: self.isFeatured,
        // "brand": self.selectedBrand,
        shortDescription: '',
        longDescription: '',
        isActive: self.isActive,
        attributeType : self.AttributeType
        // colors : this.SelectedColor,
        // sizeChart : this.selectedSize
        // "modelNo": self.modelNo
      };
      axios
        .post('https://seematti-backend.getdesignworks.com/products/create', productData, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('Token'),
          },
        })
        .then(function (response) {
          if (response.data.status == 'success') {
           
            self.showTable = true;
            self.showForm = false;
            self.fetchData();
            self.defaultMode = 'Edit'
            //  localStorage.setItem('Token',response.data.data);
            //  self.router.navigate(['dashboard'])
          }
        })
        .catch(function (error) {
          // let errorData = error.response.data.data;
          // let errorString = 'Please Check Below Error \n\n';
          // if (errorData) {
          //   for (let i = 0; i < errorData.length; i++) {
          //     errorString +=
          //       errorData[i].param + ' - ' + errorData[i].msg + '\n';
          //   }
          //   alert(errorString);
          // }
        });
      } else if(self.defaultMode == 'Edit'){
        var reqData = {
          // productId: self.productId,
          skuId: self.productSkuID,
          name: self.productName,
          images: self.reqImageArray,
          type: self.productType,
          //allowedAttributes: [],
          bgGradient: {
            color1 : self.color1,
            color2 : self.color2,
        },
          
          variations: theReqArray,
          // regularPrice: this.regularprice,
          // salePrice: this.sellPrice,
          // "tags": self.productTags,
          category: self.categoryList,
          isBestSeller: self.isBestSeller,
          isFeaturedProduct: self.isFeatured,
          // "brand": self.selectedBrand,
          shortDescription: '',
          longDescription: '',
          isActive: self.isActive,
          productId: self.productId,
          attributeType : self.AttributeType
          // colors : this.SelectedColor,
          // sizeChart : this.selectedSize
          // "modelNo": self.modelNo
        };
        axios
          .put('https://seematti-backend.getdesignworks.com/products/update', reqData, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('Token'),
            },
          })
          .then(function (response) {
            if (response.data.status == 'success') {
             
              self.showTable = true;
              self.showForm = false;
              self.fetchData();
              self.defaultMode = 'Add'
              //  localStorage.setItem('Token',response.data.data);
              //  self.router.navigate(['dashboard'])
            }
          })
          .catch(function (error) {
            // let errorData = error.response.data.data;
            // let errorString = 'Please Check Below Error \n\n';
            // if (errorData) {
            //   for (let i = 0; i < errorData.length; i++) {
            //     errorString +=
            //       errorData[i].param + ' - ' + errorData[i].msg + '\n';
            //   }
            //   alert(errorString);
            // }
          });
      }
    }
  }
  editProduct(product) {
    this.resettingForm();
    this.defaultMode = 'Edit'
    console.log(product);
    this.productId = product.productId
    this.productType = product.type;
    this.productName = product.name;
    this.productSkuID = product.Skuld;
    this.categoryList = product.category;
    this.color1 = product.color1;
    this.color2 = product.color2;
    this.isActive = product.active;
    this.isBestSeller = product.bestseller;
    this.isFeatured = product.featured;
    this.productImages = product.images;
    this.AttributeType = product.attributeType;
   // alert(this.categoryList);
    //this.productImages.push(item.)
    // this.productImages = product.imag192.168.1.123
    this.showTable = !this.showTable;
    this.showForm = !this.showForm;
    var tempArray:any[] = []
     tempArray.push(product.attributeArray[0].attributes[0].label);
      tempArray.push(product.attributeArray[0].attributes[1].label);
      this.selectedAttribute = tempArray;
      console.log('media images');
      console.log(this.productImages[0].image.url);
      for(var i = 0; i < this.productImages.length; i++){
        this.reqImageArray.push(this.productImages[i]._id)
      }
      for(var i = 0; i < this.attributeList.length; i++) {
        for(var j = 0; j < this.selectedAttribute.length; j++) {
          if(this.attributeList[i]._id == this.selectedAttribute[j]){
            this.reqList.push(this.attributeList[i]);
          }
        }
      } 
      if(this.reqList.length == 2){
        var tmpArray = [];
        var tmpArray1 = [];
        for(var  i = 0; i < product.attributeArray.length; i++){
          if(tmpArray.indexOf(product.attributeArray[i].attributes[0].value) == -1)
          tmpArray.push(product.attributeArray[i].attributes[0].value);
          if(tmpArray1.indexOf(product.attributeArray[i].attributes[1].value) == -1)
          tmpArray1.push(product.attributeArray[i].attributes[1].value);
        }
        this.reqList[0]['selectedValues'] = tmpArray;
        this.reqList[1]['selectedValues'] = tmpArray1;
        this.addVariation();
        console.log('vari');
        console.log(this.reqAttrArray);
        for(var i = 0; i < this.reqAttrArray.length; i++){
          this.reqAttrArray[i].skuId = product.attributeArray[i].skuId;
          this.reqAttrArray[i].regularPrice = product.attributeArray[i].regularPrice;
          this.reqAttrArray[i].salePrice = product.attributeArray[i].salePrice;
          if(this.reqAttrArray[i].image != undefined){
            this.reqAttrArray[i].image = product.attributeArray[i].imageId.image.url;
            this.reqAttrArray[i].imageId = product.attributeArray[i].imageId._id;
          }
          if(this.reqAttrArray[i].colorCode == undefined)         
          this.reqAttrArray[i].colorCode = product.attributeArray[i].colorCode;
        }
       
      }
      // var attrArray : any = [];
      // var reqobj = {};
      // for(var i = 0; i < product.attributeArray.length; i++){
      //   reqobj[product.attributeArray[0].attributes[0].label] = product.attributeArray[i].attributes[0].value;
      //   reqobj[product.attributeArray[0].attributes[1].label] = product.attributeArray[i].attributes[1].value;
      //   attrArray.push(reqobj);
      // }
      // console.log('rooch');
      // console.log(attrArray);
      // for(var i = 0; i < product.attributeArray.length;i++){
      //   for(var j = 0; j < product.attributeArray[i].attributes.length;j++){
      //     console.log('hanif')
      //      console.log(product.attributeArray[i].attributes[j])
      //      if(this.reqList[i]['name'] == product.attributeArray[i].attributes[j].label){
      //         this.reqList[i]['selectedValues'] = [product.attributeArray[i].attributes[j].value]
      //      }
      //   }
        
      // }
      
      // this.reqList[0]['selectedValues'] = [(product.attributeArray[0].attributes[0].value)];
      // if(this.reqList[0]['selectedValues'].indexOf(product.attributeArray[1].attributes[0].value) == -1)
      // this.reqList[0]['selectedValues'].push(product.attributeArray[1].attributes[0].value);
      // this.reqList[1]['selectedValues']= [(product.attributeArray[0].attributes[1].value)];
      // if(this.reqList[1]['selectedValues'].indexOf(product.attributeArray[1].attributes[0].value) == -1)
      // this.reqList[1]['selectedValues'].push(product.attributeArray[1].attributes[0].value);
      console.log('current reqlist');
      console.log(this.reqList);
    
  }
  addVariation(){
     this.reqAttrArray = []; 
     var reqObj : any = {};

     if(this.reqList.length == 0){
       alert('Select Attributes');
     }
    else if(this.reqList.length == 2){
      for(var i= 0; i < this.reqList[0].selectedValues.length; i++){
        for(var j = 0; j < this.reqList[1].selectedValues.length; j++){
          reqObj = {};
          reqObj.label1 = this.reqList[0].name;
          reqObj.label2 = this.reqList[1].name;
          reqObj.combination = this.reqList[0].selectedValues[i] + ' - ' + this.reqList[1].selectedValues[j];
          this.reqAttrArray.push(reqObj);
        }
      }
    } else if(this.reqList.length == 3){
      for(var i= 0; i < this.reqList[0].selectedValues.length; i++){
        for(var j = 0; j < this.reqList[1].selectedValues.length; j++){
          reqObj = {};
          reqObj.label1 = this.reqList[0].name;
          reqObj.label2 = this.reqList[1].name;
          reqObj.label3 = this.reqList[3].name;
          reqObj.combination = this.reqList[0].selectedValues[i] + ' - ' + this.reqList[1].selectedValues[j];
          //this.reqAttrArray.push(reqObj);
        for(var k = 0; k < this.reqList[2].selectedValues.length; k++){
          reqObj.combination = reqObj.combination + ' - ' + this.reqList[2].selectedValues[k];
        }
        }
      }
    }
    
    else if(this.reqList.length == 1){
      this.reqAttrArray.push(this.reqList[0].selectedValues);
    } 
    // else if(this.reqList.length == 3){
    //   for(var)
    // }
  
   console.log('desired variation');
   console.log(this.reqAttrArray);
  }
  onAttributeSelected(event){
    this.reqList = [];
    this.reqAttrArray = [];
    for(var i = 0; i < this.attributeList.length; i++) {
      for(var j = 0; j < event.length; j++) {
        if(this.attributeList[i]._id == event[j]){
          this.reqList.push(this.attributeList[i]);
        }
      }
    }
    console.log(this.reqList);   
  }
  onAttributeValueSelected(event,values){
    // console.log(event);
    // console.log(values);
    this.reqAttrArray = [];
   var reqObj = {};
    reqObj[values] = event;
    console.log(reqObj);
    console.log(this.reqList)
  }
  openModel(index){
    //alert(index);
    this.currentIndex = index;
    this.AttributeMediaVisible = true;
  }
  getImageId(){
    this.navService.formRefreshAnnouncedSource.subscribe((item) => {
      this.reqAttrArray[this.currentIndex].imageId = item[item.length - 1]._id;
      this.reqAttrArray[this.currentIndex].image = item[item.length - 1].image.url
      console.log('media images');
      console.log(this.reqAttrArray);
    });
    this.handleCancel();
  }
  resettingForm(){
  this.productType = 'simple';
  this.productName = '';
  this.productSkuID = '';
  this.productImages = [];
  this.productTags = [];
  this.categoryList = [];
  this.selectedBrand = '';
  this.modelNo = '';
  this.regularprice = '';
  this.sellPrice = '';
  this.isBestSeller = false;
  this.isFeatured = false;
  this.isActive = false;
  this.productShortDescription = '';
  this.productLongDescription = '';
  this.allowedAttributes = [];
  this.variations = [];
  this.brandList = [];
  //this.listOfCategory = [];
  this.listOfBrands = [];
  this.selectedAttribute = [];
  this.sizeChart = [];
  this.selectedSize = [];
  this.SelectedColor = [];
  this.reqList  = [];
  this.color1 = '';
  this.color2 = '';
  this.reqImageArray = [];
  this.defaultMode = 'Add';
  this.productId = '';
  this.resImage  = []
  this.defaultMode = 'Add';
  this.reqList = [];

  }
  alertBox(): void {
    this.modalService.confirm({
      nzTitle: 'Are You Sure You Want to Cancel ?',
      nzOkText: 'Yes',
      nzCancelText: 'No',
      nzOnOk: () => this.showFormDetails(),
    })
}
}
