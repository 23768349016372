import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { scrollToTop } from '../../shared/dummy';
import axios, { Axios } from 'axios';



interface DataItem {
  name: string;
  acceptedValues: any;
  Type: any;
  icon: any;
  showOnFilter: any;
  createdBy: any;
  createdAt: any;
  lastUpdateBy: any;
  lastUpdateAt: any;
}

@Component({
  selector: 'app-spec-value',
  templateUrl: './spec-value.component.html',
  styleUrls: ['./spec-value.component.css'],
})
export class SpecValueComponent implements OnInit {
  selectedCategory: string;
  selectedStatus: string;
  searchInput: any;
  displayData = [];

  showTable: boolean = true;
  showForm: boolean = false;

  MediaVisible = false;
  isOkLoading = false;

  specValueName = '';
  specType = 'Value'
  acceptedValues = [];
  isShowOnFilter = false;
  


  validateForm: FormGroup;

  orderColumn = [
    {
      title: 'S.NO',
    },
    {
      title: 'Name',
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
    },
    {
      title: 'Type',
      compare: (a: DataItem, b: DataItem) => a.Type - b.Type,
    },
    {
      title: 'accepted Values',
      compare: (a: DataItem, b: DataItem) =>
        a.acceptedValues - b.acceptedValues,
    },
    {
      title: 'Icon',
      compare: (a: DataItem, b: DataItem) => a.icon - b.icon,
    },
    {
      title: 'showOnFilter',
      compare: (a: DataItem, b: DataItem) => a.showOnFilter - b.showOnFilter,
    },
    {
      title: 'Created By',
      compare: (a: DataItem, b: DataItem) => a.createdBy - b.createdBy,
    },
    {
      title: 'Created At',
      compare: (a: DataItem, b: DataItem) => a.createdAt - b.createdAt,
    },
    {
      title: 'Last Updated By',
      compare: (a: DataItem, b: DataItem) => a.lastUpdateBy - b.lastUpdateBy,
    },
    {
      title: 'Last Updated At',
      compare: (a: DataItem, b: DataItem) => a.lastUpdateAt - b.lastUpdateAt,
    },

    {
      title: 'Action',
    },
  ];
  fetchData(){
    axios.get(
      //'https://seematti-backend.getdesignworks.com/specValues/get', 
      'https://oasis.getdesignworks.com/specValues/get',
    {
      headers: {
            
        Authorization: 'Bearer ' + localStorage.getItem('Token'),
      },
    })
    .then( (response) => { 
      console.log(response.data.data);
       this.productsList = response.data.data.map((data:any,index: number) => {
         return {
          name: data.name,
          acceptedValues: data.acceptedValues,
          type: data.type,
          icon: 'assets/images/others/thumb-11.jpg',
          showOnFilter: data.isShowOnFilter,
          createdBy: data.createdBy,
          createdAt: data.createdAt,
          lastUpdateBy: data.lastUpdatedBy,
          lastUpdateAt: data.lastUpdatedAt,
         }
       });
     
     
      //console.log(response.data.status);
     
    })
    .catch(function (error) {
      console.log(error);
     //alert(error.response.data.message);
    });
  }
  productsList : DataItem [] = [];
  // productsList = [
  //   {
      
  //     name: 'RAM',
  //     acceptedValues: '2',
  //     type: 'storage',
  //     icon: 'assets/images/others/thumb-11.jpg',
  //     showOnFilter: 'yes',
  //     createdBy: 'user1',
  //     createdAt: 'user3',
  //     lastUpdateBy: 'user2',
  //     lastUpdateAt: 'user4',
  //   },
  //   {
      
  //     name: 'Color',
  //     acceptedValues: '5',
  //     type: 'storage',
  //     icon: 'assets/images/others/thumb-12.jpg',
  //     showOnFilter: 'no',
  //     createdBy: 'user1',
  //     createdAt: 'user3',
  //     lastUpdateBy: 'user2',
  //     lastUpdateAt: 'user4',
  //   },
  //   {
      
  //     name: 'Size',
  //     acceptedValues: '3',
  //     type: 'storage',
  //     icon: 'assets/images/others/thumb-10.jpg',
  //     showOnFilter: 'yes',
  //     createdBy: 'user1',
  //     createdAt: 'user3',
  //     lastUpdateBy: 'user2',
  //     lastUpdateAt: 'user4',
  //   },
  // ];

  // constructor(private tableSvc : TableService) {
  //     this.displayData = this.productsList
  // }

  // search(): void {
  //     const data = this.productsList
  //     this.displayData = this.tableSvc.search(this.searchInput, data )
  // }

  constructor(private fb: FormBuilder){}

  categoryChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.name === value))
    this.displayData = data;
  }

  statusChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.feature === value))
    //   : (this.displayData = data);
    this.displayData = data;
  }

  ngOnInit(): void {
    scrollToTop();
    this.fetchData();
    this.validateForm = this.fb.group({});
  }

  showFormDetails() {
    this.showTable = !this.showTable;
    this.showForm = !this.showForm;
  }

  // MODEL SECTION
  showMedia() {
    this.MediaVisible = true;
  }
  handleCancel(): void {
    this.MediaVisible = false;
  }

  resetForm(): void {
    this.validateForm.reset();
  }

  doChanges(){
    var self = this;
    if(self.specValueName == ''){
      alert('Enter Spec Value Name');
    }else if(self.acceptedValues.length == 0){
      alert('Select Accepted Values');
    }else {
      var specValueDetails = {
        "name": self.specValueName,
        "type": self.specType,
        "acceptedValues": self.acceptedValues,
        "isShowOnFilter": self.isShowOnFilter
      }
      axios
      .post(
        'https://seematti-backend.getdesignworks.com/specValues/create',
        specValueDetails,
        {
          headers: {
            
            Authorization: 'Bearer ' + localStorage.getItem('Token'),
          },
        }
      )
      .then(function (response) {
        if (response.data.status == 'success') {
          console.log(response.data.data);
          self.fetchData();
          self.showTable = !self.showTable;
          self.showForm = !self.showForm;
          self.specValueName = '';
          self.specType = 'Value';
          self.acceptedValues = [];
          self.isShowOnFilter = false;
         //  localStorage.setItem('Token',response.data.data);
         //  self.router.navigate(['dashboard'])
        }
      })
      .catch(function (error) {
        let errorData = error.response.data.data;
        let errorString = 'Please Check Below Error \n\n';
        if (errorData) {
          for (let i = 0; i < errorData.length; i++) {
            errorString += errorData[i].param + ' - ' + errorData[i].msg + '\n';
          }
          alert(errorString);
        }
      });
    }
  }
}
