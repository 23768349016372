<div class="container">
    <div class="row justify-content-center">
        <div class="col-12 d-flex justify-content-between heading-section mb-5" [ngClass]="{ 'media-page-top' : mediaPage}">
            <div class="">
                <h2>Media Library</h2>
            </div>
            <!-- <div class="">
                <a nz-button nzType="primary">
                    <span>Refresh</span>
                </a>
            </div> -->
            <div class="upload-button" style="background-color: #b2beb5;">
                <!-- <a nz-button nzType="primary">
                    <span>Upload</span>
                </a> -->
                <!-- <nz-upload nzListType="picture">
                <button nz-upload nz-button><i nz-icon nzType="upload"></i>Upload</button>
                </nz-upload> -->

                <label for='input-file'>Browse</label>
                <input id='input-file' type='file' multiple (change)="uploadImage($event)">
            </div>
        </div>

        <!-- <div *ngFor="let image of gallery; let i=index" class="col-lg-2">
            <nz-card [ngClass]="{'image-card-section' : setBorder}" class="text-center" #local>
                <img (click)="selectedImage(i)" class="img-fluid" [src]="image.src" alt="">
            </nz-card>
        </div> -->

        <div *ngFor="let image of gallery; let i=index" class="col-lg-2 main-card mb-5">
            <card class="text-center card">
                <input #check type="checkbox" id="{{image._id}}">
                <label for="{{image._id}}">
                          <img class="img-fluid" (click)="checkChange(image._id)" [src]="'https://seematti-backend.getdesignworks.com/'+image.image.url" />
                           <span class="cover-checkbox">
                             <svg viewBox="0 0 12 10">
                               <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                             </svg>
                           </span>
                       </label>
            </card>
        </div>
    </div>
</div>

<!-- <div class="cont-bg"> -->

<!-- <div class="cont-main"> -->
<!-- <div class="cont-checkbox" *ngFor="let image of gallery; let i=index">
    <input type="checkbox" id="{{image.id}}" />
    <label for="{{image.id}}">
          <img [src]="image.src" />
           <span class="cover-checkbox">
             <svg viewBox="0 0 12 10">
               <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
             </svg>
           </span>
       </label>
</div> -->
<!-- </div> -->
<!-- </div> -->