import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import axios, { Axios } from 'axios';
import * as moment from 'moment';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  reqUserArray : any = [];
  dashboard = false;
  startDate = '';
  endDate = '';
  fetchData(){
    axios
    .get('https://seematti-backend.getdesignworks.com/dashboard', {})
    .then((response) => {
      console.log(response.data.data.interestedProducts);
      this.reqUserArray = response.data.data.count.wishlistedProducts;
      //this.listOfCategory = initialResult.categoriesForDropdown;
      // this.listOfBrands = initialResult.brandsForFilter;

      // this.productsList = initialResult.products.map(
      //   (data: any, index: number) => {
      //     return {
      //       Skuld: data.skuId,
      //       name: data.name,
      //       type: data.type,
      //       image: data.images[0].image.url,
      //       bestseller: data.isBestSeller,
      //       featured: data.isFeaturedProduct,
      //       active: data.isActive,
      //       // brand : data.brand.name,
      //       // modelNumber : data.modelNo,
      //       regularPrice: data.regularPrice,
      //       salePrice: data.salePrice,
      //       // tags : data.tags,
      //       category: data.category,
      //       specification: 'spec group', //data.specifications[0].specGroup.name
      //     };
      //   }
      // );
      // console.log(this.productsList);

      

      //console.log(response.data.status);
    })
    .catch(function (error) {
      alert(error);
    });
  }

  orderColumn = [
    {
      title:'S.NO',
      width:'50px'
    },
    {
      title: 'Product Name',
      width:'100px'
    },
    {
      title: 'Name',
      width:'100px'
    },
    {
      title: 'Mobile Number',
      width:'150px'
    },
  ];

  // Report = [
  //   {
  //     productName:"Men's Shirt",
  //     date:'12-05-2021',
  //     time:'08.22pm'
  //   },
  //   {
  //     productName:"Men's Shirt",
  //     date:'12-05-2021',
  //     time:'08.22pm'
  //   },
  //   {
  //     productName:"Men's Shirt",
  //     date:'12-05-2021',
  //     time:'08.22pm'
  //   },
  //   {
  //     productName:"Men's Shirt",
  //     date:'12-05-2021',
  //     time:'08.22pm'
  //   },
  //   {
  //     productName:"Men's Shirt",
  //     date:'12-05-2021',
  //     time:'08.22pm'
  //   },
  //   {
  //     productName:"Men's Shirt",
  //     date:'12-05-2021',
  //     time:'08.22pm'
  //   },
  // ]

  Report = [
    {
      productId:"2514",
      name:'name',
      mobileNumber:'9658741245'
    },
    {
      productId:"2515",
      name:'name',
      mobileNumber:'9658741245'
    },
    {
      productId:"2516",
      name:'name',
      mobileNumber:'9658741245'
    },
    {
      productId:"2814",
      name:'name',
      mobileNumber:'9658741245'
    },
    {
      productId:"2714",
      name:'name',
      mobileNumber:'9658741245'
    },
    {
      productId:"8914",
      name:'name',
      mobileNumber:'9658741245'
    },
  ]

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.fetchData();
    if (this.router.url == '/dashboard') {
      this.dashboard = true;
    }
  }


  getWishlistedProductsByDateFilter() {

    var fromDate = moment(this.startDate).format();
    var toDate = moment(this.endDate).format();

    axios
    .post('https://seematti-backend.getdesignworks.com/custom/interested/get-all-wishlisted-products-by-date-filter', {
      fromDate: fromDate,
      toDate: toDate
    })
    .then((response) => {
      this.reqUserArray = response?.data?.data?.interestedProducts;
    })
    .catch(function (error) {
      alert(error);
    });
  }

  clearTable() {
    this.reqUserArray = [];
  }

}
