import { Component, OnInit } from '@angular/core';
import { NgOtpInputConfig } from 'ng-otp-input';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  showOtpComponent = true;

  config :NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: ''
  };

  constructor() { }

  ngOnInit(): void {
  }

}
