import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-to-offer-page',
  templateUrl: './add-to-offer-page.component.html',
  styleUrls: ['./add-to-offer-page.component.css'],
})
export class AddToOfferPageComponent implements OnInit {
  selectedType = '';
  MediaVisible = false;

  dateFormat = 'dd-MM-yyyy';
  isOkLoading = false;

  gotoURL: any;
  subscription: Subscription;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.subscription = this.route.params.subscribe((data) => {
      this.gotoURL = JSON.parse(data['state']);
    });
  }

  goBack() {
    this.router.navigate([this.gotoURL]);
  }

  showMedia() {
    this.MediaVisible = true;
  }
  handleCancel(): void {
    this.MediaVisible = false;
  }
}
