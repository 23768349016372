import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { scrollToTop } from '../../shared/dummy';
import axios, { Axios } from 'axios';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';

interface DataItem {
  name: string;
  image: any;
  banner: any;
  children: any;
  featured: any;
  active: any;
  priority: number;
  specification: any;
  createdBy: any;
  createdAt: any;
  lastUpdateBy: any;
  lastUpdateAt: any;
}

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css'],
})
export class CategoryComponent implements OnInit {
  selectedCategory: string;
  selectedStatus: string;
  searchInput: any;
  displayData = [];
  actionButton = 'Add';
  categoryimage : any[] = []; 
  reqImageArray : any[] = [];

  showTable: boolean = true;
  showForm: boolean = false;

  MediaVisible = false;

  isOkLoading = false;

  validateForm: FormGroup;
  categoryName = '';
  parentId = '';
  categoryImage = [];
  bannerImage = [];
  isFeatured = false;
  isActive = false;
  categoryId = '';
  confirmAlert = false;

  currentUrl = '';

  orderColumn = [
    {
      width:'50px',
      title: 'S.NO',
    },
    {
      width:'80px',
      title: 'Name',
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
    },
    {
      width:'80px',
      title: 'Image',
      compare: (a: DataItem, b: DataItem) => a.image - b.image,
    },
    {
      title: 'Banner',
      compare: (a: DataItem, b: DataItem) => a.banner - b.banner,
    },
    // {
    //   title: 'Children',
    //   compare: (a: DataItem, b: DataItem) => a.children - b.children,
    // },
    {
      width:'100px',
      title: 'Featured',
      compare: (a: DataItem, b: DataItem) => a.featured - b.featured,
    },
    {
      width:'100px',
      title: 'Active',
      compare: (a: DataItem, b: DataItem) => a.active - b.active,
    },
    // {
    //   title: 'priority',
    //   compare: (a: DataItem, b: DataItem) => a.priority - b.priority,
    // },
    // {
    //   title: 'Specification',
    //   compare: (a: DataItem, b: DataItem) =>
    //     a.specification.localeCompare(b.specification),
    // },
    {
      width:'150px',
      title: 'Created By',
      compare: (a: DataItem, b: DataItem) => a.createdBy - b.createdBy,
    },
    {
      width:'150px',
      title: 'Created At',
      compare: (a: DataItem, b: DataItem) => a.createdAt - b.createdAt,
    },
    {
      width:'200px',
      title: 'Last Updated By',
      compare: (a: DataItem, b: DataItem) => a.lastUpdateBy - b.lastUpdateBy,
    },
    {
      width:'200px',
      title: 'Last Updated At',
      compare: (a: DataItem, b: DataItem) => a.lastUpdateAt - b.lastUpdateAt,
    },

    {
      width:'50px',
      title: 'Action',
    },
    // {
    //   title: 'Offer',
    // },
  ];

  productsList: DataItem[] = [];

  // SPECIFICATION TAGS SECTION

  tags = [];

  // constructor(private tableSvc : TableService) {
  //     this.displayData = this.productsList
  // }

  // search(): void {
  //     const data = this.productsList
  //     this.displayData = this.tableSvc.search(this.searchInput, data )
  // }
  constructor(
    private fb: FormBuilder,
    public navService: NavService,
    private router: Router,
    private modalService: NzModalService
  ) {}

  fetchData() {
    axios
      .get('https://seematti-backend.getdesignworks.com/categories', {})
      .then((response) => {
        console.log(response.data.data);
        //console.log(response.data.data[0].image._id)
        var initialResult = response.data.data;
        this.productsList = initialResult.map((data: any, index: number) => {
          return {
            id: data._id,
            name: data.name,
           image: data.image.image.url,
           imageId : data.image._id,
           banner: data.image.image.url,
            children: data.children,
            featured: data.featured,
            active: data.isActive,
            priority: data.priority,
            // specification: 'spec data',
            createdBy: data.createdBy,
            createdAt: data.createdAt,
            lastUpdateBy: data.lastUpdatedBy,
            lastUpdateAt: data.lastUpdatedAt,
          };
        });
        console.log(this.productsList);

        //console.log(response.data.status);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  categoryChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.name === value))
    this.displayData = data;
  }

  statusChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.feature === value))
    //   : (this.displayData = data);
    this.displayData = data;
  }

  AddOffer() {
    this.router.navigate([
      '/add-offer',
      { state: JSON.stringify(this.currentUrl) },
    ]);
  }

  ngOnInit(): void {
    scrollToTop();
    this.fetchData();
    this.currentUrl = this.router.url;
    this.validateForm = this.fb.group({});
  }

  addspecificantion(a: any, b: any) {
    if (a.triggerWidth !== null && b.triggerWidth !== null) {
      this.tags.push(a.value);
    }
    console.log(b);
  }

  showFormDetails() {
    this.showTable = !this.showTable;
    this.showForm = !this.showForm;
    this.actionButton = 'Add'
    this.clearFields();
  }

  // MODEL SECTION
  showMedia() {
    this.MediaVisible = true;
  }
  handleCancel(): void {
    this.MediaVisible = false;
  }

  // FORM RESET SECTION

  resetForm(): void {
    this.clearFields();
    this.validateForm.reset();
  }
  sendMedia() {
    this.reqImageArray = [];
    this.navService.formRefreshAnnouncedSource.subscribe((item) => {
      var reqImage: any[] = [];
      reqImage = item;
      this.categoryImage = [];
      console.log('media images');
      for(var i = 0; i < reqImage.length; i++){
        this.reqImageArray.push(reqImage[i]._id)
        this.categoryImage.push(reqImage[i].image.url)
      }
    });
    this.handleCancel();
  }

  doChanges() {
    var self = this;
    if (self.categoryName == '') {
      alert('Enter Category Name');
    } else if (self.categoryImage.length == 0) {
      alert('Select Image for Category');
    }
    // else if(this.bannerImage.length == 0){
    //   alert('Select Image for Banner');
    // }
    else {
      //alert('success');
      var categoryData : any;
      if(this.actionButton == 'Add'){
        categoryData = {
          name: self.categoryName,
          isMainParent: true,
          imageId: self.reqImageArray[0],
          bannerImageId: self.reqImageArray[0],
          featured: self.isFeatured,
          isActive: self.isActive,
        };
        console.log(categoryData);
      axios
        .post('https://seematti-backend.getdesignworks.com/categories/create', categoryData, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('Token'),
          },
        })
        .then(function (response) {
          if (response.data.status == 'success') {
            console.log(response.data.data);
            self.fetchData();
            self.showTable = !self.showTable;
            self.showForm = !self.showForm;
            //  localStorage.setItem('Token',response.data.data);
            //  self.router.navigate(['dashboard'])
          }
        })
        .catch(function (error) {
          let errorData = error.response.data.data;
          let errorString = 'Please Check Below Error \n\n';
          if (errorData) {
            for (let i = 0; i < errorData.length; i++) {
              errorString +=
                errorData[i].param + ' - ' + errorData[i].msg + '\n';
            }
            alert(errorString);
          }
        });
      } else if(this.actionButton == 'Edit'){
        categoryData = {
          id: self.categoryId,
          name: self.categoryName,
          isMainParent: true,
          imageId: self.reqImageArray[self.reqImageArray.length - 1],
          bannerImageId: self.reqImageArray[self.reqImageArray.length - 1],
          featured: self.isFeatured,
          isActive: self.isActive,
        };
        console.log(categoryData);
      axios
        .put('https://seematti-backend.getdesignworks.com/categories/update', categoryData, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('Token'),
          },
        })
        .then(function (response) {
          if (response.data.status == 'success') {
            console.log(response.data.data);
            self.fetchData();
            self.showTable = !self.showTable;
            self.showForm = !self.showForm;
            //  localStorage.setItem('Token',response.data.data);
            //  self.router.navigate(['dashboard'])
          }
        })
        .catch(function (error) {
          let errorData = error.response.data.data;
          let errorString = 'Please Check Below Error \n\n';
          if (errorData) {
            for (let i = 0; i < errorData.length; i++) {
              errorString +=
                errorData[i].param + ' - ' + errorData[i].msg + '\n';
            }
            alert(errorString);
          }
        });
      }
       
      
    }
  }
  editCategory(item){
     console.log(item);
     this.categoryImage = [];
     this.categoryId = item.id;
     this.categoryName = item.name;
     this.isFeatured = item.featured;
     this.isActive = item.active;
     this.categoryImage.push(item.image);
     this.reqImageArray.push(item.imageId)
     this.showTable = !this.showTable;
    this.showForm = !this.showForm;
    this.actionButton ='Edit';

  }

  clearFields () {
    this.actionButton = 'Add';
    this.categoryName = '';
    this.parentId = '';
    this.categoryImage = [];
    this.categoryimage = [];
    this.bannerImage = [];
    this.isFeatured = false;
    this.isActive = false;
    this.categoryId = '';
  }

  alertBox(): void {
    this.modalService.confirm({
      nzTitle: 'Are You Sure You Want to Cancel ?',
      nzOkText: 'Yes',
      nzCancelText: 'No',
      nzOnOk: () => this.showFormDetails(),
    })
}
}
