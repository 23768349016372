<nz-card [ngClass]="{'dashboard-height' : dashboard}">
    <div class="row m-b-30">
        <div class="col-lg-12">
            <div class="d-md-flex " [ngClass]="{'dashboard-page' : dashboard}">
                <h3>Most WishListed</h3>
                <!-- <a class="view-all-link" *ngIf="dashboard" routerLink="/report">View All</a > -->
            </div>
        </div>
    </div>

    <nz-table #wishListedTable [nzData]="wishListed" [nzShowPagination]="false" [nzScroll]="{ y: '350px' }">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzWidth]="column.width" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of mostWishlistedProducts;  let i=index">
                <td>{{i+1}}</td>
                <td>
                    <nz-avatar nzShape="square" [nzSize]="80" nzIcon="picture" [nzSrc]="'https://seematti-backend.getdesignworks.com/'+item.images[0].image.url"></nz-avatar>
                </td>
                <td>{{item.name}}</td>
                <td>{{item.wishlistedCount}}</td>
            </tr>
        </tbody>
    </nz-table>
</nz-card>