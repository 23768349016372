import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { scrollToTop } from '../../shared/dummy';
import axios, { Axios } from 'axios';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css'],
})
export class MediaComponent implements OnInit {
  @Output() imageSelected = new EventEmitter<any>();
  setBorder = false;
  mediaPage = false;
  imageurls = [];

  gallery = [];
  reqImages = [];
  //   {
  //     id: 'myCheckbox-1',
  //     src: 'assets/images/others/thumb-9.jpg',
  //   },
  //   {
  //     id: 'myCheckbox-2',
  //     src: 'assets/images/others/thumb-8.jpg',
  //   },
  //   {
  //     id: 'myCheckbox-3',
  //     src: 'assets/images/others/thumb-11.jpg',
  //   },
  //   {
  //     id: 'myCheckbox-4',
  //     src: 'assets/images/others/thumb-13.jpg',
  //   },
  //   {
  //     id: 'myCheckbox-5',
  //     src: 'assets/images/others/thumb-15.jpg',
  //   },
  //   {
  //     id: 'myCheckbox-6',
  //     src: 'assets/images/others/thumb-12.jpg',
  //   },
  //   {
  //     id: 'myCheckbox-7',
  //     src: 'assets/images/others/thumb-10.jpg',
  //   },
  //   {
  //     id: 'myCheckbox-8',
  //     src: 'assets/images/others/thumb-15.jpg',
  //   },
  //   {
  //     id: 'myCheckbox-9',
  //     src: 'assets/images/others/thumb-12.jpg',
  //   },
  //   {
  //     id: 'myCheckbox-10',
  //     src: 'assets/images/others/thumb-10.jpg',
  //   },
  //   {
  //     id: 'myCheckbox-11',
  //     src: 'assets/images/others/thumb-8.jpg',
  //   },
  //   {
  //     id: 'myCheckbox-12',
  //     src: 'assets/images/others/thumb-11.jpg',
  //   },
  //   {
  //     id: 'myCheckbox-13',
  //     src: 'assets/images/others/thumb-13.jpg',
  //   },
  //   {
  //     id: 'myCheckbox-14',
  //     src: 'assets/images/others/thumb-15.jpg',
  //   },
  // ];

  MaxSelect = [];

  constructor(private router: Router, public navService: NavService) {}

  // selectedImage(a: any) {
  //   this.imageurls.splice(a, 1);
  //   console.log(a);
  // }
  fetchData() {
    axios
      .get('https://seematti-backend.getdesignworks.com/media', {})
      .then((response) => {
        console.log(response.data.data);
        this.gallery = response.data.data;
        // this.gallery = initialResult.map((data: any, index: number) => {
        //   return {
        //     name : data.name,
        //     logo : data.image.image.url,
        //     website : data.website

        //   }});
        //   console.log(this.productsList[0].logo);

        //console.log(response.data.status);
      })
      .catch(function (error) {
        alert(error.response.data.message);
      });
  }

  checkChange(event) {
    let currentUrl = this.router.url;
    if (currentUrl == '/media') {
      event.checked = false;
      return null;
    }
    // console.log(this.MaxSelect.length);
    if (currentUrl == '/products') {
      if (this.MaxSelect.length >= 4 && this.MaxSelect.indexOf(event) == -1) {
        alert('You Can Only Select 4 Images Maximum');
        event.checked = false;
      }
      if (this.MaxSelect.indexOf(event) == -1) {
        if (this.MaxSelect.length < 4) {
          this.MaxSelect.push(event);
          for (var i = 0; i < this.gallery.length; i++) {
            for (var j = 0; j < this.MaxSelect.length; j++) {
              //console.log(this.gallery[i]._id + ' ' + this.MaxSelect);
              if (this.gallery[i]._id == this.MaxSelect[j]) {
                for (var k = 0; k < this.reqImages.length; k++) {
                  if (this.gallery[i]._id == this.reqImages[k]._id) {
                    this.reqImages.splice(k, 1);
                  }
                }
                this.reqImages.push(this.gallery[i]);
              }
            }
          }
        }
      } else {
        for(var i = 0; i < this.reqImages.length; i++) {
          for(var j = 0; j < this.MaxSelect.length; j++){
            if(this.reqImages[i]._id == this.MaxSelect[j]){
              this.reqImages.splice(i,1);
            }
          } 
        }
        this.MaxSelect.splice(this.MaxSelect.indexOf(event), 1);
      }
    } else if (
      currentUrl == '/brands' ||
      currentUrl == '/category' ||
      currentUrl == '/offer-page'
    ) {
      if (this.MaxSelect.length >= 1 && this.MaxSelect.indexOf(event) == -1) {
        alert('You Can Only Select 1 Images Maximum');
        event.checked = false;
        return false;
      }
      if (this.MaxSelect.indexOf(event) == -1) {
        if (this.MaxSelect.length < 1) {
          this.MaxSelect.push(event);
          for (var i = 0; i < this.gallery.length; i++) {
            for (var j = 0; j < this.MaxSelect.length; j++) {
              //console.log(this.gallery[i]._id + ' ' + this.MaxSelect);
              if (this.gallery[i]._id == this.MaxSelect[j]) {
                for (var k = 0; k < this.reqImages.length; k++) {
                  if (this.gallery[i]._id == this.reqImages[k]._id) {
                    this.reqImages.splice(k, 1);
                  }
                }
                this.reqImages.push(this.gallery[i]);
              }
            }
          }
        }
      } else {
        for(var i = 0; i < this.reqImages.length; i++) {
          for(var j = 0; j < this.MaxSelect.length; j++){
            if(this.reqImages[i]._id == this.MaxSelect[j]){
              this.reqImages.splice(i,1);
            }
          } 
        }
        this.MaxSelect.splice(this.MaxSelect.indexOf(event), 1);
      }
    }

    // }
    // console.log(this.MaxSelect);
    // for (var i = 0; i < this.gallery.length; i++) {
    //   for (var j = 0; j < this.MaxSelect.length; j++) {
    //     console.log(this.gallery[i]._id + ' ' + this.MaxSelect);
    //     if (this.gallery[i]._id == this.MaxSelect[j]) {
    //       for (var k = 0; k < this.reqImages.length; k++) {
    //         if (this.gallery[i]._id == this.reqImages[k]._id) {
    //           this.reqImages.splice(k, 1);
    //         }
    //       }
    //       this.reqImages.push(this.gallery[i]);
    //     }
    //   }
    // }
    console.log(this.reqImages);
    this.navService.publishFormRefresh(this.reqImages);

    // console.log('after', this.MaxSelect.length);
    console.log(currentUrl);
  }

  ngOnInit(): void {
    scrollToTop();
    this.fetchData();
    if (this.router.url == '/media') {
      this.mediaPage = true;
      
    }
  }
  uploadImage(event) {
    var formData = new FormData();
    console.log(event.target.files);
    for (var i = 0; i < event.target.files.length; i++) {
      formData.append('images', event.target.files[i]);
    }

    axios
      .post('https://seematti-backend.getdesignworks.com/media/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('Token'),
        },
      })
      .then((response) => {
        if (response.data.message == 'media uploaded') {
          this.fetchData();
        }
        //this.gallery = response.data.data;
        // this.gallery = initialResult.map((data: any, index: number) => {
        //   return {
        //     name : data.name,
        //     logo : data.image.image.url,
        //     website : data.website

        //   }});
        //   console.log(this.productsList[0].logo);

        //console.log(response.data.status);
      })
      .catch(function (error) {
        alert(error.response.data.message);
      });
  }
}
