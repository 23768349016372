<nz-card>
    <div class="row m-b-30">
        <div class="col-lg-3" [ngClass]="{'col-lg-12' : dashboard}">
            <div class="d-md-flex " [ngClass]="{'dashboard-page' : dashboard}">
                <!-- <h3>{{dashboard ? 'WishList' : 'Interested Products List'}}</h3> -->
                <h3>WishList</h3>
                <a class="view-all-link" *ngIf="dashboard" routerLink="/report">View All</a >
            </div>
        </div>
        <div *ngIf="!dashboard" class="col-lg-2">
            <nz-date-picker class="w-100" [(ngModel)]="startDate"  nzPlaceHolder="Start Date"></nz-date-picker>
        </div>
        <div *ngIf="!dashboard" class="col-lg-2">
            <nz-date-picker class="w-100" [(ngModel)]="endDate"  nzPlaceHolder="End Date"></nz-date-picker>
        </div>

        <div *ngIf="!dashboard" class="col-lg-2">
            <!-- <button class="btn btn-link">Clear</button> -->
            <a nz-button nzType="primary" (click)="getWishlistedProductsByDateFilter()" class="btn btn-primary">Filter</a >
        </div>
        
        <div *ngIf="!dashboard" class="col-lg-2 clear-button">
            <!-- <button class="btn btn-link">Clear</button> -->
            <a (click)="clearTable()" class="view-all-link">Clear</a >
        </div>
    </div>

    <nz-table #ReportTable [nzData]="Report">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzWidth]="column.width" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of reqUserArray;  let i=index">
                <td>{{i+1}}</td>
                <td>{{item.product.name}}</td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.customerName}}
                    </div>
                </td>
                <td>
                    {{item.customerPhone}}
                </td>
                <!-- <td>
                    <div class="d-flex align-items-center">
                        {{item.product.name}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center time-section">
                        {{item.createdAt.split('T')[0]}}
                        <span>  {{item.createdAt.split('T')[1].split('.')[0]}}</span>
                    </div>
                </td> -->


                <!-- <td class="">
                    <a (click)="editAttribute(item)" class="m-r-5" nz-button nzType="default" nzShape="circle" nz-tooltip nzTooltipTitle="Edit">
                        <i nz-icon nzType="edit" theme="outline"></i>
                    </a>
                <button nz-button nzType="default" nzShape="circle" nz-tooltip nzTooltipTitle="Delete">
                        <i nz-icon nzType="delete" theme="outline"></i>
                    </button>
                </td> -->
                </tr>
                </tbody>
                </nz-table>
</nz-card>