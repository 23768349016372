import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-products-forms',
  templateUrl: './add-products-forms.component.html',
  styleUrls: ['./add-products-forms.component.css']
})
export class AddProductsFormsComponent implements OnInit {

  

  VariationHeading=[
   {heading:'Variation'},
   {heading:'Regular Price'},
   {heading:'SKU'},
   {heading:'Sale Price'},
   {heading:'Photo'}
  ]

  VariationData=[
    {
      Variation:'AMD',
      RegularPrice:'41500',
      SKU:'54795',
      SalePrice:'35200',
      Photo:''
    },
    {
      Variation:'AMD',
      RegularPrice:'41500',
      SKU:'54795',
      SalePrice:'35200',
      Photo:''
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
