<nz-card *ngIf="showTable">
    <div class="row m-b-30">
        <div class="col-lg-8">
            <div class="d-md-flex">
                <div class="m-b-10 m-r-20">
                    <nz-input-group [nzPrefix]="prefixTemplate">
                        <input type="text" nz-input placeholder="Search Project" [(ngModel)]="searchInput">
                    </nz-input-group>
                    <ng-template #prefixTemplate>
                        <i nz-icon nzType="search" class="opacity-05"></i>
                    </ng-template>
                </div>
                <div class="m-b-10 m-r-20 d-flex align-items-center">
                    <nz-select style="min-width: 220px;" class="w-100" nzPlaceHolder="Category" [(ngModel)]="selectedCategory" (ngModelChange)="categoryChange($event)">
                        <nz-option nzLabel="All" nzValue="All"></nz-option>
                        <nz-option nzLabel="Home Decoration" nzValue="Home Decoration"></nz-option>
                        <nz-option nzLabel="Eletronic" nzValue="Eletronic"></nz-option>
                        <nz-option nzLabel="Jewellery" nzValue="Jewellery"></nz-option>
                    </nz-select>
                </div>
                <div class="m-b-10 m-r-20 d-flex align-items-center">
                    <nz-select style="min-width: 220px;" class="w-100" nzPlaceHolder="Status" [(ngModel)]="selectedStatus" (ngModelChange)="statusChange($event)">
                        <nz-option nzLabel="All" nzValue="All"></nz-option>
                        <nz-option nzLabel="In Stock" nzValue="in stock"></nz-option>
                        <nz-option nzLabel="Out Of Stock" nzValue="out of stock"></nz-option>
                    </nz-select>
                </div>
            </div>
        </div>
        <div class="col-lg-4 text-right add-button-section">
            <a (click)="showFormDetails()" nz-button nzType="primary">
                <i nz-icon nzType="plus-circle" theme="outline"></i>
                <span>Add User</span>
            </a>
        </div>
    </div>
    <nz-table #productsListTable [nzData]="productsList">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of productsListTable.data;  let i=index">
                <td>{{i+1}}</td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.name}}
                    </div>
                </td>
                <td>{{item.role}}</td>
                <td>{{item.email}}</td>
                <td>{{item.contact}}</td>
                <td class="">
                    <a class="m-r-5" nz-button nzType="default" nzShape="circle" nz-tooltip nzTooltipTitle="Edit">
                        <i nz-icon nzType="edit" theme="outline"></i>
                    </a>
                </td>
            </tr>
        </tbody>
    </nz-table>
</nz-card>

<!-- (ngModelChange)="search()" Goes to line 7 in top -->
<!-- (ngModelChange)="categoryChange($event)"   Goes to line 14 -->
<!-- (ngModelChange)="statusChange($event)"  goes to line 22 -->

<!-- ADD ATTRIBUTE FORM DESIGN STARTS  -->

<div class="container" *ngIf="showForm">

    <div class="row">
        <div class="col-7 title-text">
            <div class=" mb-5">
                <h2>Add New User</h2>
            </div>
        </div>
        <div class="col-5 close">
            <div class="close-icon ">
                <i (click)="showFormDetails()" nz-icon nzType="close" nzTheme="outline" title="close"></i>
            </div>
        </div>

        <div class="col-lg-10">
            <form [formGroup]="formDetails" nz-form>
                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="UserName">Name</nz-form-label>
                    <nz-form-control nzErrorTip="Please input your username!">
                        <nz-input-group>
                            <input type="text" nz-input id="UserName" placeholder="Enter Attribute Name">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="UserRole">Role</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select id="UserRole" nzShowSearch class="w-100" nzPlaceHolder="None">
                                <nz-option nzValue="Admin" nzLabel="Admin"></nz-option>
                                <nz-option nzValue="User" nzLabel="User"></nz-option>

                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="UserEmail">Email</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text" nz-input id="UserEmail" placeholder="" email>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="UserMobile">Mobile Number</nz-form-label>
                    <nz-form-control nzErrorTip="Email is not valid">
                        <nz-input-group>
                            <input type="text" nz-input id="UserMobile" placeholder="" maxlength="10">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>



                <nz-form-item class="text-center ml-5">
                    <nz-form-control>
                        <button (click)="resetForm()" type="reset" class="mr-5" nz-button nzType="default">Reset</button>
                        <a><button (click)="showFormDetails()" nz-button nzType="primary">Add</button></a>
                    </nz-form-control>
                </nz-form-item>

            </form>

        </div>
    </div>
</div>

<!-- ADD ATTRIBUTE FORM DESIGN ENDS  -->