import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css'],
})
export class OfferComponent implements OnInit {
  ShowForm = false;
  showTable = true;

  MediaVisible = false;
  isOkLoading = false;

  currentUrl = ''

  selectedType = '';
  selectedTable = 'All Products';
  ShowOffer = true;

  dateFormat = 'dd-MM-yyyy';

  // TABLE HEADING

  orderColumn = [
    {
      title: 'S.NO',
    },
    {
      title: 'SKU ID',
    },
    {
      title: 'Name',
    },
    {
      title: 'Image',
    },
    {
      title: 'Brand',
    },
    {
      title: 'Model Number',
    },
    {
      title: 'Regular Price',
    },
    {
      title: 'Sale Price',
    },
    {
      title: 'Category',
    },
    {
      title: 'Action',
    },
  ];

  CategoryHeading = [
    {
      title: 'S.NO',
    },
    {
      title: 'Name',
    },
    {
      title: 'Image',
    },
    {
      title: 'Banner',
    },
    {
      title: 'Featured',
    },
    {
      title: 'Specification',
    },

    {
      title: 'Action',
    },
  ];

  // OfferHeading = [
  //   {
  //     title: 'S.NO',
  //   },
  //   {
  //     title: 'SKU ID',
  //   },
  //   {
  //     title: 'Name',
  //   },
  //   {
  //     title: 'Banner',
  //   },
  //   {
  //     title: 'Regular Price',
  //   },
  //   {
  //     title: 'Discount Price',
  //   },
  //   {
  //     title: 'Offer Price',
  //   },
  //   {
  //     title: 'Action',
  //   },
  // ];

  // TABLE DETAILS

  productsList = [
    {
      skuID: '12452',
      name: 'name 1',
      image: '',
      brand: 'brand 1',
      model: 'model 1',
      regularPrice: '1200',
      salePrice: '1000',
      category: 'category 1',
    },
  ];

  CategoryList = [
    {
      name: 'Sample name 1',
      image: '',
      banner: '',
      featured: 'featured',
      specification: 'spec value',
    },
  ];

  // OfferList = [
  //   {
  //     skuID: '3256',
  //     name: 'sample name 1',
  //     banner: '',
  //     regularPrice: '1600',
  //     discount: '400',
  //     offerPrice: '1200',
  //   },
  // ];

  constructor(private router : Router) {}

  ngOnInit(): void {
    this.currentUrl = this.router.url;
  }

  addOffer() {
    this.router.navigate([
      '/add-offer',
      { state: JSON.stringify(this.currentUrl) },
    ]);
  }

  handleCancel(): void {
    this.MediaVisible = false;
  }

  showMedia() {
    this.MediaVisible = true;
  }

  // provinceChange(a: string): void {
  //   // this.selectedType = this.cityData[value][0];
  //   console.log('works');
  // }
}
