import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { scrollToTop } from '../../shared/dummy';
import axios, { Axios } from 'axios';


interface DataItem {
  id: any;
  name: string;
  active: any;
  createdBy: any;
  createdAt: any;
  lastUpdateBy: any;
  lastUpdateAt: any;
}

@Component({
  selector: 'app-spec-group',
  templateUrl: './spec-group.component.html',
  styleUrls: ['./spec-group.component.css'],
})
export class SpecGroupComponent implements OnInit {
  selectedCategory: string;
  selectedStatus: string;
  searchInput: any;
  displayData = [];

  showTable: boolean = true;
  showForm: boolean = false;

  validateForm: FormGroup;

  specName = '';
  isActive = false;
  id = '';
  isEdit = false;

  fetchData(){
    axios.get('https://oasis.getdesignworks.com/specGroup/get', {
      headers: {
            
        Authorization: 'Bearer ' + localStorage.getItem('Token'),
      },
    })
    .then( (response) => { 
      console.log(response.data.data);
       this.productsList = response.data.data.map((data:any,index: number) => {
         return {
           id: data._id,
          name: data.name,
          active: data.isActive,
          createdBy: data.createdBy,
          createdAt: data.createdAt,
          lastUpdateBy: data.lastUpdatedBy,
          lastUpdateAt: data.lastUpdatedAt,
         }
       });
     
     
      //console.log(response.data.status);
     
    })
    .catch(function (error) {
      console.log(error);
     //alert(error.response.data.message);
    });
  }

  orderColumn = [
    {
      title: 'S.NO',
    },
    {
      title: 'Name',
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
    },
    {
      title: 'Active',
      compare: (a: DataItem, b: DataItem) => a.active - b.active,
    },
    {
      title: 'Created By',
      compare: (a: DataItem, b: DataItem) => a.createdBy - b.createdBy,
    },
    {
      title: 'Created At',
      compare: (a: DataItem, b: DataItem) => a.createdAt - b.createdAt,
    },
    {
      title: 'Last Updated By',
      compare: (a: DataItem, b: DataItem) => a.lastUpdateBy - b.lastUpdateBy,
    },
    {
      title: 'Last Updated At',
      compare: (a: DataItem, b: DataItem) => a.lastUpdateAt - b.lastUpdateAt,
    },
    {
      title: 'Action',
    },
  ];
  productsList : DataItem [] = [];
  // productsList = [
  //   {
      
  //     name: 'Processor',
  //     active: 'inactive',
  //     createdBy: 'user1',
  //     createdAt: 'user2',
  //     lastUpdateBy: 'user3',
  //     lastUpdateAt: 'user4',
  //   },
  //   {
      
  //     name: 'Memory',
  //     active: 'inactive',
  //     createdBy: 'user1',
  //     createdAt: 'user2',
  //     lastUpdateBy: 'user3',
  //     lastUpdateAt: 'user4',
  //   },
  //   {
      
  //     name: 'Processor',
  //     active: 'active',
  //     createdBy: 'user1',
  //     createdAt: 'user2',
  //     lastUpdateBy: 'user3',
  //     lastUpdateAt: 'user4',
  //   },
  //   {
      
  //     name: 'Processor',
  //     active: 'inactive',
  //     createdBy: 'user1',
  //     createdAt: 'user2',
  //     lastUpdateBy: 'user3',
  //     lastUpdateAt: 'user4',
  //   },
  // ];

  // constructor(private tableSvc : TableService) {
  //     this.displayData = this.productsList
  // }

  // search(): void {
  //     const data = this.productsList
  //     this.displayData = this.tableSvc.search(this.searchInput, data )
  // }
  constructor(private fb: FormBuilder){}

  categoryChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.name === value))
    this.displayData = data;
  }

  statusChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.feature === value))
    //   : (this.displayData = data);
    this.displayData = data;
  }

  ngOnInit(): void {
    scrollToTop();
    this.fetchData();
    this.validateForm = this.fb.group({});
  }

  showFormDetails() {
    this.showTable = !this.showTable;
    this.showForm = !this.showForm;
  }

  resetForm(): void {
    this.validateForm.reset();
  }
  doChanges(){
    var self = this;
    alert(self.isActive);
    if(self.specName == ''){
      alert('Enter Spec Name');
    } else {
      if(!this.isEdit){
        var specGroupDetails = {
          "name": this.specName,
          "isActive": this.isActive
        }
        axios
        .post(
          'https://seematti-backend.getdesignworks.com/specGroup/create',
          specGroupDetails,
          {
            headers: {
              
              Authorization: 'Bearer ' + localStorage.getItem('Token'),
            },
          }
        )
        .then(function (response) {
          if (response.data.status == 'success') {
            console.log(response.data.data);
            self.fetchData();
            self.showTable = !self.showTable;
            self.showForm = !self.showForm;
            self.specName = '';
            self.isActive = false;
           //  localStorage.setItem('Token',response.data.data);
           //  self.router.navigate(['dashboard'])
          }
        })
        .catch(function (error) {
          let errorData = error.response.data.data;
          let errorString = 'Please Check Below Error \n\n';
          if (errorData) {
            for (let i = 0; i < errorData.length; i++) {
              errorString += errorData[i].param + ' - ' + errorData[i].msg + '\n';
            }
            alert(errorString);
          }
        });
      } else {
        var specGroupDetail = {
          "id": self.id,
          "name": self.specName,
          "isActive": self.isActive
        }
        axios
        .post(
          'https://seematti-backend.getdesignworks.com/specGroup/update',
          specGroupDetail,
          {
            headers: {
              
              Authorization: 'Bearer ' + localStorage.getItem('Token'),
            },
          }
        )
        .then(function (response) {
          if (response.data.status == 'success') {
            console.log(response.data.data);
            self.fetchData();
            self.showTable = !self.showTable;
            self.showForm = !self.showForm;
            self.specName = '';
            self.isActive = false;
           //  localStorage.setItem('Token',response.data.data);
           //  self.router.navigate(['dashboard'])
          }
        })
        .catch(function (error) {
          let errorData = error.response.data.data;
          let errorString = 'Please Check Below Error \n\n';
          if (errorData) {
            for (let i = 0; i < errorData.length; i++) {
              errorString += errorData[i].param + ' - ' + errorData[i].msg + '\n';
            }
            alert(errorString);
          }
        });
      }
     
     
     
    }
  }
  editSpecGroup(item){
   var self = this;
   self.specName = item.name;
   self.id = item.id;
   self.isActive = item.active;
   this.isEdit = true;
   this.showTable = !this.showTable;
    this.showForm = !this.showForm;
  }
}
