<nz-card *ngIf="showTable">
    <div class="row m-b-30">
        <div class="col-lg-8">
            <div class="d-md-flex">
                <div class="m-b-10 m-r-20">
                    <nz-input-group [nzPrefix]="prefixTemplate">
                        <input type="text" nz-input placeholder="Search Project" [(ngModel)]="searchFilter">
                    </nz-input-group>
                    <ng-template #prefixTemplate>
                        <i nz-icon nzType="search" class="opacity-05"></i>
                    </ng-template>
                </div>
                <!-- <div class="m-b-10 m-r-20 d-flex align-items-center">
                    <nz-select style="min-width: 220px;" class="w-100" nzPlaceHolder="Category" [(ngModel)]="selectedCategory" (ngModelChange)="categoryChange($event)">
                        <nz-option nzLabel="All" nzValue="All"></nz-option>
                        <nz-option nzLabel="Home Decoration" nzValue="Home Decoration"></nz-option>
                        <nz-option nzLabel="Eletronic" nzValue="Eletronic"></nz-option>
                        <nz-option nzLabel="Jewellery" nzValue="Jewellery"></nz-option>
                    </nz-select>
                </div>
                <div class="m-b-10 m-r-20 d-flex align-items-center">
                    <nz-select style="min-width: 220px;" class="w-100" nzPlaceHolder="Status" [(ngModel)]="selectedStatus" (ngModelChange)="statusChange($event)">
                        <nz-option nzLabel="All" nzValue="All"></nz-option>
                        <nz-option nzLabel="In Stock" nzValue="in stock"></nz-option>
                        <nz-option nzLabel="Out Of Stock" nzValue="out of stock"></nz-option>
                    </nz-select>
                </div> -->
            </div>
        </div>
        <div class="col-lg-4 text-right add-button-section">
            <a (click)="showFormDetails()" nz-button nzType="primary">
                <i nz-icon nzType="plus-circle" theme="outline"></i>
                <span>Add Category</span>
            </a>
        </div>
    </div>
    <nz-table #productsListTable [nzData]="productsList">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzWidth]="column.width" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of productsListTable.data|filter: searchFilter;  let i=index">
                <td>{{i+1}}</td>
                <td>
                    <div class="d-flex align-items-center">
                        <!-- <nz-avatar nzShape="square" [nzSize]="60" nzIcon="picture" [nzSrc]="item.avatar"></nz-avatar> -->
                        {{item.name}}
                    </div>
                </td>
                <td>
                    <nz-avatar nzShape="square" [nzSize]="80" nzIcon="picture" [nzSrc]="'https://seematti-backend.getdesignworks.com/'+item.image"></nz-avatar>
                </td>
                <td>
                    <nz-avatar nzShape="square" [nzSize]="80" nzIcon="picture" [nzSrc]="'https://seematti-backend.getdesignworks.com/'+item.banner"></nz-avatar>
                </td>
                <!-- <td>{{item.children}}</td> -->
                <td>
                    <nz-badge *ngIf="item.featured == 'featured'" nzStatus="success"></nz-badge>
                    <nz-badge *ngIf="item.featured == 'not featured'" nzStatus="error"></nz-badge>
                    <span class="text-capitalize">{{item.featured}}</span>
                </td>
                <td>
                    <nz-badge *ngIf="item.active == 'active'" nzStatus="success"></nz-badge>
                    <nz-badge *ngIf="item.active == 'inactive'" nzStatus="error"></nz-badge>
                    <span class="text-capitalize">{{item.active}}</span>
                </td>
                <!-- <td>{{item.priority}}</td> -->
                <!-- <td>{{item.specification}}</td> -->
                <td>{{item.createdBy}}</td>
                <td>{{item.createdAt}}</td>
                <td>{{item.lastUpdateBy}}</td>
                <td>{{item.lastUpdateAt}}</td>

                <td class="">
                    <a class="m-r-5" nz-button nzType="default" nzShape="circle" (click)="editCategory(item)" nz-tooltip nzTooltipTitle="Edit">
                        <i nz-icon nzType="edit" theme="outline"></i>
                    </a>
                </td>

                <!-- <td>
                    <button (click)="AddOffer()" nz-button>{{!ShowOffer? 'Add to' : 'Update'}} Offer</button>
                </td> -->
            </tr>
        </tbody>
    </nz-table>
</nz-card>

<!-- (ngModelChange)="search()" Goes to line 7 in top -->


<!-- ADD CATEGORY FORM STARTS -->

<div *ngIf="showForm" class="container">
    <div class="row">
        <div class="col-7 title-text">
            <div class=" mb-5">
                <h2>{{actionButton}} Category</h2>
            </div>
        </div>
        <div class="col-5 close">
            <div class="close-icon ">
                <i (click)="showFormDetails()" nz-icon nzType="close" nzTheme="outline" title="close"></i>
            </div>
        </div>

        <div class="col-lg-10">

            <!-- <div class="col-2">
                <i nz-icon nzType="close" nzTheme="outline"></i>
            </div> -->
            <form [formGroup]="formDetails" nz-form>
                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="CategoryName">Name</nz-form-label>
                    <nz-form-control nzErrorTip="Please input your username!">
                        <nz-input-group>
                            <input type="text" [(ngModel)]="categoryName" nz-input id="CategoryName" placeholder="Enter Category Name">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <!-- <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="BrandWebsite">Parents</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select id="BrandWebsite" [(ngModel)]="parentId" nzShowSearch class="w-100" nzPlaceHolder="None">
                                <nz-option *ngFor="let category of productsList" nzValue="{{category._id}}" nzLabel="{{category.name}}"></nz-option> -->
                <!-- <nz-option nzValue="Cooling System" nzLabel="Cooling System"></nz-option>
                                <nz-option nzValue="Mother Board" nzLabel="Mother Board"></nz-option>
                                <nz-option nzValue="Desktop RAM" nzLabel="Desktop RAM"></nz-option> -->
                <!-- </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item> -->

                <!-- Specification section starts -->

                <!-- <div class="Specification-section">
                    <hr>
                    <div class="text-center mb-5 mt-5">
                        <h2>Specification</h2>
                    </div>

                    <nz-form-item>
                        <nz-form-label [nzXl]="4" [nzMd]="8" nzRequired nzFor="SpecificationGroup">Choose<br>Specification Group</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-select id="SpecificationGroup" nzShowSearch class="w-100" nzPlaceHolder="None" #selectedGroup>
                                    <nz-option nzValue="General" nzLabel="General"></nz-option>
                                    <nz-option nzValue="Highlights" nzLabel="Highlights"></nz-option>
                                    <nz-option nzValue="Seller" nzLabel="Seller"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzXl]="4" [nzMd]="8" nzRequired nzFor="SpecificationValue">Choose<br>Specification Value</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-select nzMode="multiple" id="SpecificationValue" nzShowSearch class="w-100" nzPlaceHolder="None" #selectedValue>
                                    <nz-option nzValue="RAM" nzLabel="RAM"></nz-option>
                                    <nz-option nzValue="Color" nzLabel="Color"></nz-option>
                                    <nz-option nzValue="Battery" nzLabel="Battery"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item class="text-center">
                        <nz-form-control>
                            <a>
                                <button type="button" (click)="addspecificantion(selectedGroup , selectedValue)" nz-button nzType="primary">Add Specification</button>
                            </a>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item class="mb-5">
                        <nz-form-label [nzXl]="4" [nzMd]="8" nzRequired nzFor="SpecificationValue">Selected<br>Specification</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-tag [nzColor]="'#87d068'" *ngFor="let tag of tags" nzMode="closeable" (nzOnClose)="onClose()">{{tag}}</nz-tag>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <hr>
                </div> -->

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="CategoryImage">Image</nz-form-label>
                    <nz-form-control nzErrorTip="Please input your username!">
                        <nz-input-group>
                            <button nz-button type="button" nzType="default" (click)="showMedia()">
                                <span>Upload</span>
                            </button>
                            <img style="padding: 5px;" *ngFor="let image of categoryImage" width="60" [src]="'https://seematti-backend.getdesignworks.com/'+image">

                        </nz-input-group>

                    </nz-form-control>
                </nz-form-item>

                <!-- <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="CategoryBanner">Banner</nz-form-label>
                    <nz-form-control nzErrorTip="Please input your username!">
                        <nz-input-group>
                            <button nz-button type="button" nzType="default" (click)="showMedia()">
                                <span>Upload</span>
                            </button>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item> -->

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="CategoryFeature">Feature</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-switch id="CategoryFeature" [(ngModel)]="isFeatured" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="CategoryActive">Active</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-switch id="CategoryActive" [(ngModel)]="isActive" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="text-center">
                    <nz-form-control>
                        <button (click)="alertBox()" type="reset" class="mr-5" nz-button nzType="default">Cancel</button>
                        <a><button type="button" (click)="doChanges()" nz-button nzType="primary">{{actionButton}}</button></a>
                    </nz-form-control>
                </nz-form-item>

            </form>

        </div>
    </div>
</div>

<!-- ADD CATEGORY FORM ENDS -->

<!-- MEDIA MODEL SECTION STARTS -->

<nz-modal [nzStyle]="{top : '5px'}" nzWidth="1200px" [(nzVisible)]="MediaVisible" nzTitle="Specification" (nzOnCancel)="handleCancel()" (nzOnOk)="sendMedia()" [nzOkLoading]="isOkLoading">
    <div class="container-fluid media-modal-section" *nzModalContent>
        <app-media></app-media>
    </div>
</nz-modal>

<!-- MEDIA MODEL SECTION STARTS -->

<nz-modal [(nzVisible)]="confirmAlert" nzWidth="400px" nzOkText="Yes" nzCancelText="No">
</nz-modal>