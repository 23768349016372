<div class="container">
    <div class="row">
        <div class="col-10">
            <div class="text-center mb-5">
                <h2>Add New Specification Value</h2>
            </div>
            <form nz-form>
                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ValueName">Name</nz-form-label>
                    <nz-form-control nzErrorTip="Please input your username!">
                        <nz-input-group>
                            <input type="text" nz-input id="ValueName" placeholder="Enter Attribute Name">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ValueType">Type</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select id="ValueType" nzShowSearch class="w-100" nzPlaceHolder="None">
                                <nz-option nzValue="Value" nzLabel="Value"></nz-option>
                                <nz-option nzValue="Description" nzLabel="Description"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ValueAccepted">Accepted Values</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select nzMode="tags" id="ProductTags" nzShowSearch class="w-100" nzPlaceHolder="Enter Values">
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="specIcon">Icon</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <button nz-button nzType="default">
                                <span>Upload</span>
                            </button>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ValueFilterShow">Show On Filter</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-switch id="ValueFilterShow" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="text-center">
                    <nz-form-control>
                        <button class="mr-5" nz-button nzType="default">Reset</button>
                        <a routerLink="/specValue"><button nz-button nzType="primary">Add</button></a>
                    </nz-form-control>
                </nz-form-item>

            </form>

        </div>
    </div>
</div>