import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import axios, { Axios } from 'axios';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  passwordVisible = false;

  // submitForm(): void {
  //     for (const i in this.loginForm.controls) {
  //         this.loginForm.controls[ i ].markAsDirty();
  //         this.loginForm.controls[ i ].updateValueAndValidity();
  //     }
  // }

  constructor(private router: Router) {}

  ngOnInit(): void {
    // this.loginForm = this.fb.group({
    //     userName: [ null, [ Validators.required ] ],
    //     password: [ null, [ Validators.required ] ]
    // });
  }
  loggedIn() {
    if ((<HTMLInputElement>document.getElementById('username')).value == '') {
      alert('Enter Email Id');
    } else if (
      (<HTMLInputElement>document.getElementById('password')).value == ''
    ) {
      alert('Enter Password');
    } else {
      var userDetails = {
        email: (<HTMLInputElement>document.getElementById('username')).value,
        password: (<HTMLInputElement>document.getElementById('password')).value,
      };

      let self = this;

      axios
        .post(
          'https://seematti-backend.getdesignworks.com/auth/login',
          userDetails
          // {
          //   // headers: {

          //   //   //Authorization: 'Bearer ' + localStorage.getItem('Token'),
          //   // },
          // }
        )
        .then(function (response) {
          if (response.data.status == 'success') {
            console.log(response.data.data);
            localStorage.setItem('Token', response.data.data);
            self.router.navigate(['dashboard']);
          }
        })
        .catch(function (error) {
          let errorData = error.response.data.data;
          let errorString = 'Please Check Below Error \n\n';
          if (errorData) {
            for (let i = 0; i < errorData.length; i++) {
              errorString +=
                errorData[i].param + ' - ' + errorData[i].msg + '\n';
            }
            alert(errorString);
          }
        });
      console.log(userDetails);
    }
    //this.router.navigate(['dashboard'])
  }
}
