<nz-card>
    <div class="row m-b-30">
        <div class="col-lg-8">
            <div class="d-md-flex">
                <div class="m-b-10 m-r-20">
                    <nz-input-group [nzPrefix]="prefixTemplate">
                        <input type="text" nz-input placeholder="Search Project" [(ngModel)]="searchInput">
                    </nz-input-group>
                    <ng-template #prefixTemplate>
                        <i nz-icon nzType="search" class="opacity-05"></i>
                    </ng-template>
                </div>
                <div class="m-b-10 m-r-20 d-flex align-items-center">
                    <nz-select style="min-width: 220px;" class="w-100" nzPlaceHolder="Status">
                        <nz-option nzLabel="All" nzValue="all"></nz-option>
                        <nz-option nzLabel="Approved" nzValue="approved"></nz-option>
                        <nz-option nzLabel="Pending" nzValue="pending"></nz-option>
                        <nz-option nzLabel="Rejected" nzValue="rejected"></nz-option>
                    </nz-select>
                </div>
            </div>
        </div>
        <div class="col-lg-4 text-right add-button-section">
            <a nz-button nzType="primary">
                <i nz-icon nzType="file-excel" theme="outline"></i>
                <span>Export</span>
            </a>
        </div>
    </div>
    <nz-table #ordersListTable [nzData]="ordersList">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of ordersListTable.data;  let i=index">
                <td>{{i+1}}</td>
                <td>
                    <div class="d-flex align-items-center">
                        <nz-avatar [nzSize]="30" nzIcon="user" [nzSrc]="item.avatar"></nz-avatar>
                        <h6 class="m-l-10 m-b-0">{{item.name}}</h6>
                    </div>
                </td>
                <td>{{item.date}}</td>
                <td>${{item.amount | number:'3.2-5'}}</td>
                <td>
                    <nz-badge *ngIf="item.status == 'approved'" nzStatus="success"></nz-badge>
                    <nz-badge *ngIf="item.status == 'rejected'" nzStatus="error"></nz-badge>
                    <nz-badge *ngIf="item.status == 'pending'" nzStatus="processing"></nz-badge>
                    <span class="text-capitalize">{{item.status}}</span>
                </td>
                <td class="">
                    <a class="m-r-5" nz-button nzType="default" nzShape="circle" nz-tooltip nzTooltipTitle="Edit">
                        <i nz-icon nzType="edit" theme="outline"></i>
                    </a>
                    <!-- <button nz-button nzType="default" nzShape="circle" nz-tooltip nzTooltipTitle="Delete">
                        <i nz-icon nzType="delete" theme="outline"></i>
                    </button> -->
                </td>
            </tr>
        </tbody>
    </nz-table>
</nz-card>

<!-- (ngModelChange)="search()" GOES TO LINE 7 -->