import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import axios, { Axios } from 'axios';


@Component({
  selector: 'app-most-wishlisted-products',
  templateUrl: './most-wishlisted-products.component.html',
  styleUrls: ['./most-wishlisted-products.component.css']
})
export class MostWishlistedProductsComponent implements OnInit {
  dashboard = false;

  orderColumn = [
    {
      title: 'S.NO',
      width: '80px',
    },
    {
      title: 'Product Image',
      width: '150px',
    },
    {
      title: 'Product Name',
      width: '150px',
    },
    {
      title: 'Count',
      width: '100px',
    },
  ];

  wishListed = [
    {
      productImage: '../../../assets/images/others/product-1.jpg',
      productName:'Name',
      count:'12'
    },
    {
      productImage: '../../../assets/images/others/product-2.jpg',
      productName:'Name',
      count:'12'
    },
    {
      productImage: '../../../assets/images/others/product-3.jpg',
      productName:'Name',
      count:'12'
    },
  ];
  mostWishlistedProducts : any[] = [];  
  constructor(private router : Router) {}
  fetchData() {
    debugger;
    axios
    .get('https://seematti-backend.getdesignworks.com/dashboard', {},
    )
    .then((response) => {
      console.log(response.data.data.count);
      //this.mostViewProducts = response.data.data.count.mostViewedProducts;
      this.mostWishlistedProducts = response.data.data.count.mostWishlistedProducts;
      //this.wishlistedProducts = response.data.data.count.wishlistedProducts;

      //this.reqUserArray = response.data.data.interestedProducts;
     

      
    })
    .catch(function (error) {
      alert(error);
    });
  }
  ngOnInit(): void {
    if (this.router.url == '/dashboard') {
      this.dashboard = true;
    }
    this.fetchData();
  }

}
