<nz-card *ngIf="showTable">
    <div class="row m-b-30">
        <div class="col-lg-8">
            <div class="d-md-flex">
                <div class="m-b-10 m-r-20">
                    <nz-input-group [nzPrefix]="prefixTemplate">
                        <input type="text" nz-input placeholder="Search Project" [(ngModel)]="searchFilter">
                    </nz-input-group>
                    <ng-template #prefixTemplate>
                        <i nz-icon nzType="search" class="opacity-05"></i>
                    </ng-template>
                </div>
                <!-- <div class="m-b-10 m-r-20 d-flex align-items-center">
                    <nz-select style="min-width: 220px;" class="w-100" nzPlaceHolder="Category" [(ngModel)]="selectedCategory" (ngModelChange)="categoryChange($event)">
                        <nz-option nzLabel="All" nzValue="All"></nz-option>
                        <nz-option nzLabel="Home Decoration" nzValue="Home Decoration"></nz-option>
                        <nz-option nzLabel="Eletronic" nzValue="Eletronic"></nz-option>
                        <nz-option nzLabel="Jewellery" nzValue="Jewellery"></nz-option>
                    </nz-select>
                </div>
                <div class="m-b-10 m-r-20 d-flex align-items-center">
                    <nz-select style="min-width: 220px;" class="w-100" nzPlaceHolder="Status" [(ngModel)]="selectedStatus" (ngModelChange)="statusChange($event)">
                        <nz-option nzLabel="All" nzValue="All"></nz-option>
                        <nz-option nzLabel="In Stock" nzValue="in stock"></nz-option>
                        <nz-option nzLabel="Out Of Stock" nzValue="out of stock"></nz-option>
                    </nz-select>
                </div> -->
            </div>
        </div>
        <div class="col-lg-4 text-right add-button-section">
            <a (click)="showTableDesign()" nz-button nzType="primary">
                <i nz-icon nzType="plus-circle" theme="outline"></i>
                <span>Add Attribute</span>
            </a>
        </div>
    </div>
    <nz-table #productsListTable [nzData]="productsList">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of productsListTable.data|filter: searchFilter; let i=index">
                <td>{{i+1}}</td>
                <td>
                    <div class="d-flex align-items-center">
                        <!-- <nz-avatar nzShape="square" [nzSize]="60" nzIcon="picture" [nzSrc]="item.avatar"></nz-avatar> -->

                        {{item.name}}

                    </div>
                </td>
                <td>{{item.values}}</td>

                <td>
                    <nz-badge *ngIf="item.isFeature == true" nzStatus="success"></nz-badge>
                    <nz-badge *ngIf="item.isFeature == false" nzStatus="error"></nz-badge>
                    <span class="text-capitalize">{{item.isFeature}}</span>
                </td>
                <td>
                    <nz-badge *ngIf="item.isActive == true" nzStatus="success"></nz-badge>
                    <nz-badge *ngIf="item.isActive == false" nzStatus="error"></nz-badge>
                    <span class="text-capitalize">{{item.isActive}}</span>
                </td>
                <td class="">
                    <a (click)="editAttribute(item)" class="m-r-5" nz-button nzType="default" nzShape="circle" nz-tooltip nzTooltipTitle="Edit">
                        <i nz-icon nzType="edit" theme="outline"></i>
                    </a>
                    <!-- <button nz-button nzType="default" nzShape="circle" nz-tooltip nzTooltipTitle="Delete">
                        <i nz-icon nzType="delete" theme="outline"></i>
                    </button> -->
                </td>
            </tr>
        </tbody>
    </nz-table>
</nz-card>

<!-- (ngModelChange)="search()" Goes to line 7 in top -->
<!-- (ngModelChange)="categoryChange($event)"   Goes to line 14 -->
<!-- (ngModelChange)="statusChange($event)"  goes to line 22 -->

<!-- ADD ATTRIBUTE FORM DESIGN STARTS  -->

<div class="container" *ngIf="showForm">

    <div class="row">
        <div class="col-7 title-text">
            <div class=" mb-5">
                <h2>{{isEdit? 'Edit': 'Add New'}} Attribute</h2>
            </div>
        </div>
        <div class="col-5 close">
            <div class="close-icon " (click)="resetForm()">
                <i nz-icon nzType="close" nzTheme="outline" title="close"></i>
            </div>
        </div>

        <div class="col-lg-10">
            <form [formGroup]="formDetails" nz-form>
                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="attributeName">Name</nz-form-label>
                    <nz-form-control nzErrorTip="Please input your username!">
                        <nz-input-group>
                            <input type="text" [(ngModel)]="name" nz-input id="attributeName" placeholder="Enter Attribute Name">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="attributeValue">Accepted Values</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select [(ngModel)]="attValues" nzMode="tags" id="" nzShowSearch class="w-100" nzPlaceHolder="Enter Values">
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="attributeFeature">Feature</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-switch [(ngModel)]="isFeatured" id="attributeFeature" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="attributeActive">Active</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-switch [(ngModel)]="isActive" id="attributeActive" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="text-center ml-5">
                    <nz-form-control>
                        <button (click)="showConfirm()" type="reset" class="mr-5" nz-button nzType="default">Cancel</button>
                        <a><button (click)="showFormDetails()" type="button" nz-button nzType="primary">{{isEdit? 'Save Changes': 'Create'}}</button></a>
                        <!-- <a *ngIf="isEdit"><button (click)="showFormDetails()" nz-button nzType="primary">Edit Attribute</button></a> -->
                    </nz-form-control>
                </nz-form-item>

            </form>

        </div>
    </div>
</div>

<!-- ADD ATTRIBUTE FORM DESIGN ENDS  -->

<!-- CONFIRM ALERT MODAL -->

<nz-modal [(nzVisible)]="confirmAlert" nzWidth="400px" nzOkText="Yes" nzCancelText="No">

</nz-modal>