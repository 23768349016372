import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-spec-value',
  templateUrl: './add-spec-value.component.html',
  styleUrls: ['./add-spec-value.component.css']
})
export class AddSpecValueComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
