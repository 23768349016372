import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { scrollToTop } from '../../shared/dummy';
import axios, { Axios } from 'axios';
import { NavService } from '../../services/nav.service';



interface DataItem {
  name: string;
  logo: any;
  website: string;
}

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.css'],
})
export class BrandsComponent implements OnInit {
  selectedCategory: string;
  selectedStatus: string;
  searchInput: any;
  displayData = [];

  showTable: boolean = true;
  showForm: boolean = false;

  MediaVisible = false;
  isOkLoading = false;

  validateForm: FormGroup;

  brandName = '';
  brandImages = [];
  isPopular = false;
  isActive = false;
  website = '';
  description = '';

  orderColumn = [
    {
      title: 'S.NO',
    },
    {
      title: 'Name',
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
    },
    {
      title: 'Logo',
      compare: (a: DataItem, b: DataItem) => a.logo - b.logo,
    },
    {
      title: 'Website',
      compare: (a: DataItem, b: DataItem) => a.website.localeCompare(b.website),
    },
    {
      title: 'Action',
    },
  ];

  productsList : DataItem[] = [];
    // {
      
    //   name: 'asus',
    //   logo: 'assets/images/others/thumb-9.jpg',
    //   website: 'https://asus.com',
    // },
    // {
      
    //   name: 'antec',
    //   logo: 'assets/images/others/thumb-10.jpg',
    //   website: 'https://antec.com',
    // },
    // {
      
    //   name: 'asus',
    //   logo: 'assets/images/others/thumb-12.jpg',
    //   website: 'https://asus.com',
    // },
    // {
      
    //   name: 'avermedia',
    //   logo: 'assets/images/others/thumb-11.jpg',
    //   website: 'https://avermedia.com',
    // },
    // {
      
    //   name: 'antec',
    //   logo: 'assets/images/others/thumb-9.jpg',
    //   website: 'https://antec.com',
    // },
    // {
      
    //   name: 'amd',
    //   logo: 'assets/images/others/thumb-9.jpg',
    //   website: 'https://amd.com',
    // },
    // {
      
    //   name: 'asus',
    //   logo: 'assets/images/others/thumb-10.jpg',
    //   website: 'https://asus.com',
    // },
 

  // constructor(private tableSvc : TableService) {
  //     this.displayData = this.productsList
  // }

  // search(): void {
  //     const data = this.productsList
  //     this.displayData = this.tableSvc.search(this.searchInput, data )
  // }
  constructor(private fb: FormBuilder,public navService:NavService){}
  fetchData(){
    axios.get('https://seematti-backend.getdesignworks.com/brands', {  
    })
    .then( (response) => {
      console.log(response.data.data);
      var initialResult = response.data.data;
      this.navService.sendBrandList(initialResult);
      this.productsList = initialResult.map((data: any, index: number) => {
        return {
          name : data.name,
          logo : data.image.image.url,
          website : data.website
        }});
     
     
      //console.log(response.data.status);
     
    })
    .catch(function (error) {
     alert(error.response.data.message);
    });
  }

  categoryChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.name === value))
    this.displayData = data;
  }

  statusChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.feature === value))
    //   : (this.displayData = data);
    this.displayData = data;
  }

  ngOnInit(): void {
    scrollToTop();
    this.fetchData();
    this.navService.parentToChild('brand');
    
    this.validateForm = this.fb.group({});
  }

  onImageSelected(selectedImage: any) {
    this.brandImages = selectedImage;
    alert('hello khjkhkj '+this.brandImages);
  }

  doChanges() {
    var self = this; 
    if(self.brandName == '') {
      alert('Enter Brand Name');
    }
    // else if(this.brandImages.length == 0){
    //   alert('Select Brand Image');
    // }
    else {
      var brandData = {
        'name' : self.brandName,
        'image' : self.brandImages[self.brandImages.length - 1],
        'description' : self.description,
        'website' : self.website,
        'isPopular' : self.isPopular,
        'isActive' : self.isActive
      }
      axios
      .post(
        'https://seematti-backend.getdesignworks.com/brands/create',
        brandData,
        {
          headers: {           
            Authorization: 'Bearer ' + localStorage.getItem('Token'),
          },
        }
      )
      .then(function (response) {
        if (response.data.status == 'success') {
          console.log(response.data.data);
          self.fetchData();
          self.showTable = !self.showTable;
          self.showForm = !self.showForm;
         //  localStorage.setItem('Token',response.data.data);
         //  self.router.navigate(['dashboard'])
        }
      })
      .catch(function (error) {
        let errorData = error.response.data.data;
        let errorString = 'Please Check Below Error \n\n';
        if (errorData) {
          for (let i = 0; i < errorData.length; i++) {
            errorString += errorData[i].param + ' - ' + errorData[i].msg + '\n';
          }
          alert(errorString);
        }
      });
      console.log('final api');
      console.log(brandData);
    }
    // this.showTable = !this.showTable;
    // this.showForm = !this.showForm;
  }

  showFormDetails(){
     this.showTable = !this.showTable;
    this.showForm = !this.showForm;
  }

  // MODEL SECTION
  showMedia() {
    this.MediaVisible = true;
  }
  handleCancel(): void {
    this.MediaVisible = false;
  }

  // FORM RESET SECTION

  resetForm(): void {
    this.validateForm.reset();
  }

  sendMedia(){
    this.navService.formRefreshAnnouncedSource.subscribe(item => {
      this.brandImages = item;
      });
      this.handleCancel();

  }
  
}
