<nz-card *ngIf="showTable">
    <div class="row m-b-30">
        <div class="col-lg-8">
            <div class="d-md-flex">
                <div class="m-b-10 m-r-20">
                    <nz-input-group [nzPrefix]="prefixTemplate">
                        <input type="text" nz-input placeholder="Search Project" [(ngModel)]="searchInput">
                    </nz-input-group>
                    <ng-template #prefixTemplate>
                        <i nz-icon nzType="search" class="opacity-05"></i>
                    </ng-template>
                </div>
                <div class="m-b-10 m-r-20 d-flex align-items-center">
                    <nz-select style="min-width: 220px;" class="w-100" nzPlaceHolder="Category" [(ngModel)]="selectedCategory" (ngModelChange)="categoryChange($event)">
                        <nz-option nzLabel="All" nzValue="All"></nz-option>
                        <nz-option nzLabel="Home Decoration" nzValue="Home Decoration"></nz-option>
                        <nz-option nzLabel="Eletronic" nzValue="Eletronic"></nz-option>
                        <nz-option nzLabel="Jewellery" nzValue="Jewellery"></nz-option>
                    </nz-select>
                </div>
                <div class="m-b-10 m-r-20 d-flex align-items-center">
                    <nz-select style="min-width: 220px;" class="w-100" nzPlaceHolder="Status" [(ngModel)]="selectedStatus" (ngModelChange)="statusChange($event)">
                        <nz-option nzLabel="All" nzValue="All"></nz-option>
                        <nz-option nzLabel="In Stock" nzValue="in stock"></nz-option>
                        <nz-option nzLabel="Out Of Stock" nzValue="out of stock"></nz-option>
                    </nz-select>
                </div>
            </div>
        </div>
        <div class="col-lg-4 text-right add-button-section">
            <a (click)="showFormDetails()" nz-button nzType="primary">
                <i nz-icon nzType="plus-circle" theme="outline"></i>
                <span>{{isEdit? 'Edit': 'Add'}} Specification Group</span>
            </a>
        </div>
    </div>
    <nz-table #productsListTable [nzData]="productsList">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of productsListTable.data;  let i=index">
                <td>{{i+1}}</td>
                <td>
                    <div class="d-flex align-items-center">
                        <!-- <nz-avatar nzShape="square" [nzSize]="60" nzIcon="picture" [nzSrc]="item.avatar"></nz-avatar> -->
                        {{item.name}}
                    </div>
                </td>
                <td>
                    <nz-badge *ngIf="item.active == 'active'" nzStatus="success"></nz-badge>
                    <nz-badge *ngIf="item.active == 'inactive'" nzStatus="error"></nz-badge>
                    <span class="text-capitalize">{{item.active}}</span>
                </td>
                <td>{{item.createdBy}}</td>
                <td>{{item.createdAt}}</td>
                <td>{{item.lastUpdateBy}}</td>
                <td>{{item.lastUpdateAt}}</td>

                <td class="">
                    <a class="m-r-5" (click)="editSpecGroup(item)" nz-button nzType="default" nzShape="circle" nz-tooltip nzTooltipTitle="Edit">
                        <i nz-icon nzType="edit" theme="outline"></i>
                    </a>
                    <!-- <button nz-button nzType="default" nzShape="circle" nz-tooltip nzTooltipTitle="Delete">
                        <i nz-icon nzType="delete" theme="outline"></i>
                    </button> -->
                </td>
            </tr>
        </tbody>
    </nz-table>
</nz-card>

<!-- (ngModelChange)="search()" Goes to line 7 in top -->

<!-- ADD SPECIFICATION GROUP FORM DESIGN STARTS  -->

<div *ngIf="showForm" class="container">
    <div class="row">
        <div class="col-7 title-text">
            <div class="mb-5">
                <h2>{{isEdit? 'Edit': 'Add New'}} Specification Group</h2>
            </div>
        </div>
        <div class="col-5 close">
            <div class="close-icon ">
                <i (click)="showFormDetails()" nz-icon nzType="close" nzTheme="outline" title="close"></i>
            </div>
        </div>

        <div class="col-lg-10">
            <form [formGroup]="formDetails" nz-form>
                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="GroupName">Name</nz-form-label>
                    <nz-form-control nzErrorTip="Please input your username!">
                        <nz-input-group>
                            <input type="text" [(ngModel)]="specName" nz-input id="GroupName" placeholder="Enter Attribute Name">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="GroupActive">Active</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-switch id="GroupActive" [(ngModel)]="isActive" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="text-center">
                    <nz-form-control>
                        <button (click)="resetForm()" type="reset" class="mr-5" nz-button nzType="default">Reset</button>
                        <a><button type="button" (click)="doChanges()" nz-button nzType="primary">{{isEdit? 'Edit': 'Add'}}</button></a>
                    </nz-form-control>
                </nz-form-item>

            </form>

        </div>
    </div>
</div>

<!-- ADD SPECIFICATION GROUP FORM DESIGN ENDS  -->