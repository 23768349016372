<div class="container">
    <div class="row">
        <div class="col-10">
            <div class="text-center mb-5">
                <h2>Add New Category</h2>
            </div>
            <form nz-form>
                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="CategoryName">Name</nz-form-label>
                    <nz-form-control nzErrorTip="Please input your username!">
                        <nz-input-group>
                            <input type="text" nz-input id="CategoryName" placeholder="Enter Category Name">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="BrandWebsite">Parents</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select id="BrandWebsite" nzShowSearch class="w-100" nzPlaceHolder="None">
                                <nz-option nzValue="Processor" nzLabel="Processor"></nz-option>
                                <nz-option nzValue="Cooling System" nzLabel="Cooling System"></nz-option>
                                <nz-option nzValue="Mother Board" nzLabel="Mother Board"></nz-option>
                                <nz-option nzValue="Desktop RAM" nzLabel="Desktop RAM"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="BrandSpecification">Specification</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select nzMode="multiple" id="BrandSpecification" nzShowSearch class="w-100" nzPlaceHolder="None">
                                <nz-option nzValue="Processor Specification" nzLabel="Processor Specification"></nz-option>
                                <nz-option nzValue="Cooling System Specification" nzLabel="Cooling System Specification"></nz-option>
                                <nz-option nzValue="Mother Board Specification" nzLabel="Mother Board Specification"></nz-option>
                                <nz-option nzValue="Graphics Card Specification" nzLabel="Graphics Card Specification"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="CategoryImage">Image</nz-form-label>
                    <nz-form-control nzErrorTip="Please input your username!">
                        <nz-input-group>
                            <button nz-button nzType="default">
                                <span>Upload</span>
                            </button>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="CategoryBanner">Banner</nz-form-label>
                    <nz-form-control nzErrorTip="Please input your username!">
                        <nz-input-group>
                            <button nz-button nzType="default">
                                <span>Upload</span>
                            </button>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="CategoryFeature">Feature</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-switch id="CategoryFeature" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="CategoryActive">Active</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-switch id="CategoryActive" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="text-center">
                    <nz-form-control>
                        <button class="mr-5" nz-button nzType="default">Reset</button>
                        <a routerLink="/category"><button nz-button nzType="primary">Add</button></a>
                    </nz-form-control>
                </nz-form-item>

            </form>

        </div>
    </div>
</div>