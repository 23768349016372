import { Component, OnInit } from '@angular/core';
import { TableService } from 'src/app/shared/services/table.service';
import { scrollToTop } from '../../shared/dummy';


interface DataItem {
  name: string;
  date: string;
  amount: number;
  status:  string;
}

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {

  allChecked: boolean = false;
    indeterminate: boolean = false;
    displayData = [];
    searchInput: string

    orderColumn = [
        {
            title: 'S.NO',
        },
        {
            title: 'Customer',
            compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name)
        },
        {
            title: 'Date',
            compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name)
        },
        {
            title: 'Amount',
            compare: (a: DataItem, b: DataItem) => a.amount - b.amount,
        },
        {
            title: 'Status',
            compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name)
        },
        {
            title: 'Action'
        }
    ]

    ordersList = [
        {
            
            name: 'Erin Gonzales',
            avatar: 'assets/images/avatars/thumb-1.jpg',
            date: '8 May 2019',
            amount: 137,
            status: 'approved',
            checked : false
        },
        {
            
            name: 'Darryl Day',
            avatar: 'assets/images/avatars/thumb-2.jpg',
            date: '6 May 2019',
            amount: 322,
            status: 'approved',
            checked : false
        },
        {
            
            name: 'Marshall Nichols',
            avatar: 'assets/images/avatars/thumb-3.jpg',
            date: '1 May 2019',
            amount: 543,
            status: 'approved',
            checked : false
        },
        {
            
            name: 'Virgil Gonzales',
            avatar: 'assets/images/avatars/thumb-4.jpg',
            date: '28 April 2019',
            amount: 876,
            status: 'pending',
            checked : false
        },
        {
            
            name: 'Nicole Wyne',
            avatar: 'assets/images/avatars/thumb-5.jpg',
            date: '28 April 2019',
            amount: 241,
            status: 'approved',
            checked : false
        },
        {
            
            name: 'Riley Newman',
            avatar: 'assets/images/avatars/thumb-6.jpg',
            date: '19 April 2019',
            amount: 872,
            status: 'rejected',
            checked : false
        },
        {
            
            name: 'Pamela Wanda',
            avatar: 'assets/images/avatars/thumb-7.jpg',
            date: '18 April 2019',
            amount: 728,
            status: 'approved',
            checked : false
        },
        {
            
            name: 'Pamela Wanda',
            avatar: 'assets/images/avatars/thumb-7.jpg',
            date: '16 April 2019',
            amount: 802,
            status: 'pending',
            checked : false
        },
        {
            
            name: 'Lilian Stone',
            avatar: 'assets/images/avatars/thumb-8.jpg',
            date: '12 April 2019',
            amount: 569,
            status: 'approved',
            checked : false
        },
        {
            
            name: 'Victor Terry',
            avatar: 'assets/images/avatars/thumb-9.jpg',
            date: '10 April 2019',
            amount: 132,
            status: 'approved',
            checked : false
        },
        {
            
            name: 'Wilma Young',
            avatar: 'assets/images/avatars/thumb-10.jpg',
            date: '8 April 2019',
            amount: 528,
            status: 'rejected',
            checked : false
        },
        {
            
            name: 'Jane Wilson',
            avatar: 'assets/images/avatars/thumb-11.jpg',
            date: '8 April 2019',
            amount: 632,
            status: 'approved',
            checked : false
        },
        {
            
            name: 'Evelyn Silva',
            avatar: 'assets/images/avatars/thumb-12.jpg',
            date: '6 April 2019',
            amount: 987,
            status: 'approved',
            checked : false
        },
    ]

    // constructor(private tableSvc : TableService) {
    //     this.displayData = this.ordersList
    // }

    // search() {
    //     const data = this.ordersList
    //     this.displayData = this.tableSvc.search(this.searchInput, data )
    // }

  ngOnInit(): void {
    scrollToTop();
  }

}
