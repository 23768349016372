<div class="header">
    <div class="logo logo-dark">
        <a routerLink="/dashboard">
            <img src="../../../../assets/images/logo/new logo.png" alt="Logo">
            <img class="logo-fold" src="../../../../assets/images/logo/new logo.png" alt="Logo">
        </a>
    </div>
    <!-- <div class="logo logo-white">
        <a href="">
            <img src="https://res.cloudinary.com/oasis-it-store/image/upload/v1648107220/ttt/oasis_qgul9s.png" alt="Logo">
            <img class="logo-fold" src="https://res.cloudinary.com/oasis-it-store/image/upload/v1648107220/ttt/oasis_qgul9s.png" alt="Logo">
        </a>
    </div> -->
    <div class="nav-wrap">
        <ul class="nav-left">
            <li class="desktop-toggle">
                <a (click)="toggleFold()">
                    <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" theme="outline"></i>
                </a>
            </li>
            <li class="mobile-toggle">
                <a (click)="toggleExpand()">
                    <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
                </a>
            </li>
        </ul>

        <ul class="nav-right">
            <li>
                <div class="user-section">
                    <div class="mr-3">
                        <!-- <div nz-popover [nzPopoverContent]="contentTemplate" nzPopoverTrigger="click" nzPopoverPlacement="bottomLeft">
                            <img src="../../../../assets/AdminPanel/Logo/user.svg" alt="">
                        </div> -->
                        <a nz-dropdown [nzDropdownMenu]="menu">
                            <img src="../../../../assets/Seemati/user.svg" alt="">
                        </a>

                    </div>
                    <div class="profile-detail">
                        <h3>{{userName}}</h3>
                        <p>{{userRole}}</p>
                    </div>
                </div>
            </li>


            <!-- <li>
                <img class="logo-fold" src="../../../../assets/images/logo/oasis logo.png" alt="Logo">
                <a (click)="quickViewToggle()">
                    <i nz-icon nzType="appstore" theme="outline"></i>
                </a>
                <nz-drawer [nzWidth]="280" nzTitle="Quick View" [nzClosable]="false" [nzVisible]="quickViewVisible" nzPlacement="right" (nzOnClose)="quickViewToggle()">
                    <ng-container *nzDrawerContent>
                        <app-quick-view></app-quick-view>
                    </ng-container>
                </nz-drawer>
            </li> -->
        </ul>
    </div>
</div>

<nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
        <li (click)="Logout()" nz-menu-item> <i class="mr-3" nz-icon nzType="logout" nzTheme="outline"></i> logout</li>
        <!-- <a href=""> <i nz-icon nzType="logout" nzTheme="outline"></i> Log Out</a> -->
    </ul>
</nz-dropdown-menu>