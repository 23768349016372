<div class="row main-section-starts">
    <div class="col-lg-11">
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-sm-6 mb-3 single-card-section">
                <nz-card routerLink="/products" class="card-design1 card-design">
                    <div class="details-section">
                        <div class="user-icon">
                            <img src="../../../assets/AdminPanel/dashboard/user.svg" alt="">
                        </div>
                        <div class="user-details">
                            <h5>PRODUCTS</h5>
                            <h1>{{dashboardData.products}}</h1>
                        </div>
                    </div>
                </nz-card>
            </div>
            <div class="col-lg-4 col-sm-6 col-sm-6 mb-3 single-card-section">
                <nz-card routerLink="/category" class="card-design2 card-design">
                    <div class="details-section">
                        <div class="user-icon">
                            <img src="../../../assets/AdminPanel/dashboard/user.svg" alt="">
                        </div>
                        <div class="user-details">
                            <h5>CATEGORIES</h5>
                            <h1>{{dashboardData.categories}}</h1>
                        </div>
                    </div>
                </nz-card>
            </div>
            <div class="col-lg-4 col-sm-6 col-sm-6 mb-3 single-card-section">
                <nz-card routerLink="/customerPage" class="card-design3 card-design">
                    <div class="details-section">
                        <div class="user-icon">
                            <img src="../../../assets/AdminPanel/dashboard/user.svg" alt="">
                        </div>
                        <div class="user-details">
                            <h5>TOTAL VIEWS</h5>
                            <h1>{{dashboardData.mostViewedProductsCount}}</h1>
                        </div>
                    </div>
                </nz-card>
            </div>
            <!-- <div class="col-md-4 single-card-section">
                <nz-card>
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <p class="m-b-0 text-muted">Margin</p>
                            <h2 class="m-b-0">$8,753</h2>
                        </div>
                        <nz-tag class="m-r-0 m-b-0" [nzColor]="'red'">
                            <i nz-icon nzType="arrow-down" theme="outline"></i>
                            <span class="font-weight-semibold m-l-5">3.26%</span>
                        </nz-tag>
                    </div>
                    <div class="m-t-40">
                        <div class="d-flex justify-content-between">
                            <div>
                                <nz-badge nzStatus="default" [nzColor]="cyan"></nz-badge>
                                <span class="text-gray font-weight-semibold font-size-13">Monthly Goal</span>
                            </div>
                            <span class="text-dark font-weight-semibold font-size-13">60%</span>
                        </div>
                        <nz-progress [nzStrokeColor]="cyan" nzPercent="60" nzSize="small" [nzShowInfo]="false"></nz-progress>
                    </div>
                </nz-card>
            </div> -->
            <!-- <div class="col-md-4 single-card-section">
                <nz-card>
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <p class="m-b-0 text-muted">Margin</p>
                            <h2 class="m-b-0">$8,753</h2>
                        </div>
                        <nz-tag class="m-r-0 m-b-0" [nzColor]="'red'">
                            <i nz-icon nzType="arrow-down" theme="outline"></i>
                            <span class="font-weight-semibold m-l-5">3.26%</span>
                        </nz-tag>
                    </div>
                    <div class="m-t-40">
                        <div class="d-flex justify-content-between">
                            <div>
                                <nz-badge nzStatus="default" [nzColor]="cyan"></nz-badge>
                                <span class="text-gray font-weight-semibold font-size-13">Monthly Goal</span>
                            </div>
                            <span class="text-dark font-weight-semibold font-size-13">60%</span>
                        </div>
                        <nz-progress [nzStrokeColor]="cyan" nzPercent="60" nzSize="small" [nzShowInfo]="false"></nz-progress>
                    </div>
                </nz-card>
            </div> -->
        </div>
    </div>
</div>






<div class="row main-section-starts">
    <div class="col-lg-11">
        <div class="row">
            <div class="col-lg-6 col-12 table-section">
                <app-most-viewed-products></app-most-viewed-products>
            </div>
            <div class="col-lg-6 col-12 table-section">
                <app-most-wishlisted-products></app-most-wishlisted-products>
            </div>
            <div class="col-lg-6 col-12 table-section">
                <app-report></app-report>
            </div>
            <div class="col-lg-6 col-12 table-section">
                <app-whatsapp-shared></app-whatsapp-shared>
            </div>
        </div>
    </div>
</div>