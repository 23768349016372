import { Component, OnInit } from '@angular/core';
import { TableService } from '../../shared/services/table.service';
import { scrollToTop } from '../../shared/dummy';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import axios, { Axios } from 'axios';
import { NavService } from '../../services/nav.service';
import { NzModalService } from 'ng-zorro-antd/modal';


interface DataItem {
  name: string;
  value: any;
  feature: string;
  active: any;
}

@Component({
  selector: 'app-attributes',
  templateUrl: './attributes.component.html',
  styleUrls: ['./attributes.component.css'],
})
export class AttributesComponent  {
  selectedCategory: string;
  selectedStatus: string;
  searchInput: any;
  displayData = [];
  formDetails : any;
  isEdit = false;

  showTable: boolean = true;
  showForm: boolean = false;
  name : any;
  attValues = [];
  isFeatured = false;
  isActive = false;
  id = '';
  confirmAlert = false;

  validateForm: FormGroup;

  

  orderColumn = [
    {
      title: 'S.NO',
    },
    {
      title: 'Name',
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
    },
    {
      title: 'Value',
      compare: (a: DataItem, b: DataItem) => a.value - b.value,
    },
    {
      title: 'Feature',
      compare: (a: DataItem, b: DataItem) => a.feature.localeCompare(b.feature),
    },
    {
      title: 'Active',
      compare: (a: DataItem, b: DataItem) => a.active - b.active,
    },
    {
      title: 'Action',
    },
  ];

  productsList : DataItem [] = [];
    // {
    //   name: 'Color',
    //   value: 'blue',
    //   feature: 'featured',
    //   active: 'inactive',
    // },
    // {
    //   name: 'Size',
    //   value: 'l',
    //   feature: 'not featured',
    //   active: 'active',
    // },
    // {
    //   name: 'Material',
    //   value: 'platinum',
    //   feature: 'not featured',
    //   active: 'inactive',
    // },
    // {
    //   name: 'Color',
    //   value: 'red',
    //   feature: 'featured',
    //   active: 'inactive',
    // },
    // {
    //   name: 'Material',
    //   value: 'platinum',
    //   feature: 'not featured',
    //   active: 'active',
    // },
    // {
    //   name: 'Size',
    //   value: 'xl',
    //   feature: 'featured',
    //   active: 'active',
    // },
    // {
    //   name: 'Color',
    //   value: 'blue',
    //   feature: 'featured',
    //   active: 'active',
    // },
 

  // constructor(private tableSvc : TableService) {
  //     this.displayData = this.productsList
  // }

  // search(): void {
  //     const data = this.productsList
  //     this.displayData = this.tableSvc.search(this.searchInput, data )
  // }

  constructor(private fb: FormBuilder,public navService: NavService, private modalService: NzModalService){}

  fetchData() {
    axios.get('https://seematti-backend.getdesignworks.com/attributes', {
       
    })
    .then( (response) => {
     
  
      console.log(response.data.data);
       this.productsList = response.data.data;
       this.navService.sendAttributesList(this.productsList);
     
     
      //console.log(response.data.status);
     
    })
    .catch(function (error) {
      console.log(error);
     //alert(error.response.data.message);
    });
  }

  categoryChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.name === value))
    this.displayData = data;
  }

  statusChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.feature === value))
    //   : (this.displayData = data);
    this.displayData = data;
  }

  ngOnInit(): void {
    // window.scroll(0, 0);
    this.formDetails = this.fb.group({
      name : [''],
      values : [''],
      isFeature : [''],
      isActive : ['']

    });
    scrollToTop();
    this.fetchData();
    
    //this.validateForm = this.fb.group({});
  }

  showFormDetails() {
    let self = this;
    if(this.name == undefined || this.name == ''){
      alert('Enter Attribute Name');
    } else if(this.attValues.length == 0){
      alert('Enter Attribute Values');
    } else {
     if(!this.isEdit){
      var attributeData = {
        'name' : this.name,
        'values' : this.attValues,
        'isFeature' : this.isFeatured,
        'isActive' : this.isActive
      }
      axios
      .post(
        'https://seematti-backend.getdesignworks.com/attributes/create',
        attributeData,
        {
          headers: {
            
            Authorization: 'Bearer ' + localStorage.getItem('Token'),
          },
        }
      )
      .then(function (response) {
        if (response.data.status == 'success') {
          console.log(response.data.data);
          self.fetchData();
          self.showTable = !self.showTable;
          self.showForm = !self.showForm;
         //  localStorage.setItem('Token',response.data.data);
         //  self.router.navigate(['dashboard'])
        }
      })
      .catch(function (error) {
        let errorData = error.response.data.data;
        let errorString = 'Please Check Below Error \n\n';
        if (errorData) {
          for (let i = 0; i < errorData.length; i++) {
            errorString += errorData[i].param + ' - ' + errorData[i].msg + '\n';
          }
          alert(errorString);
        }
      });
     } else {
      var updateData = {
        'id' : this.id,
        'name' : this.name,
        'values' : this.attValues,
        'isFeature' : this.isFeatured,
        'isActive' : this.isActive
      }
      axios
      .put(
        'https://seematti-backend.getdesignworks.com/attributes/update',
        updateData,
        {
          headers: {
            
            Authorization: 'Bearer ' + localStorage.getItem('Token'),
          },
        }
      )
      .then(function (response) {
        if (response.data.status == 'success') {
          console.log(response.data.data);
          self.fetchData();
          self.showTable = !self.showTable;
          self.showForm = !self.showForm;
         //  localStorage.setItem('Token',response.data.data);
         //  self.router.navigate(['dashboard'])
        }
      })
      .catch(function (error) {
        console.log(error);
        // let errorData = error.response.data.data;
        // let errorString = 'Please Check Below Error \n\n';
        // if (errorData) {
        //   for (let i = 0; i < errorData.length; i++) {
        //     errorString += errorData[i].param + ' - ' + errorData[i].msg + '\n';
        //   }
        //   alert(errorString);
        // }
      });
     }
    
    }
    
  }

  showTableDesign() {
    this.resetValues();
    this.showTable = false;
    this.showForm = true;

  }
 

  editAttribute(data){
    this.resetValues();
    this.isEdit = true;
    this.id = data._id
    this.name = data.name;
    this.attValues = data.values;
    this.isFeatured = data.isFeature;
    this.isActive = data.isActive;
    this.showTable = !this.showTable;
    this.showForm = !this.showForm;

  }

  // resetForm(): void {
  //   this.validateForm.reset();
  // }

  resetForm() {
    this.showTable = !this.showTable;
    this.showForm = !this.showForm;
  }

  resetValues(){
    this.name = '';
    this.attValues = [];
    this.isFeatured = false;
    this.isActive = false;
    this.id = '';
    this.isEdit = false;
  }

  showConfirm(): void {
    this.modalService.confirm({
      nzTitle: 'Sure You Want to Cancel ?',
      nzOkText: 'Yes',
      nzCancelText: 'No',
      nzOnOk: () => this.resetForm(),
    })
}
}
 

