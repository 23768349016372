import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import axios, { Axios } from 'axios';


@Component({
  selector: 'app-whatsapp-shared',
  templateUrl: './whatsapp-shared.component.html',
  styleUrls: ['./whatsapp-shared.component.css']
})
export class WhatsappSharedComponent implements OnInit {

  dashboard = false;

  orderColumn = [
    {
      title:'S.NO',
      width:'50px'
    },
    {
      title: 'Product Nmae',
      width:'100px'
    },
    {
      title: 'Name',
      width:'100px'
    },
    {
      title: 'Mobile Number',
      width:'150px'
    },
  ];

  shared = [
    {
      productId:"2514",
      name:'name',
      mobileNumber:'7485124578'
    },
    {
      productId:"2515",
      name:'name',
      mobileNumber:'7485124578'
    },
    {
      productId:"2516",
      name:'name',
      mobileNumber:'7485124578'
    },
    {
      productId:"2814",
      name:'name',
      mobileNumber:'7485124578'
    },
    {
      productId:"2714",
      name:'name',
      mobileNumber:'7485124578'
    },
    {
      productId:"8914",
      name:'name',
      mobileNumber:'7485124578'
    },
  ]

  constructor(private router : Router) { }
  whatsappShared : any[] = [];
  fetchData() {
    axios
    .get('https://seematti-backend.getdesignworks.com/dashboard', {},
    )
    .then((response) => {
      console.log(response.data.data.count);
      this.whatsappShared = response.data.data.count.whatsappSharesProducts;
      // this.mostViewProducts = response.data.data.count.mostViewedProducts;
      // this.mostWishlistedProducts = response.data.data.count.mostWishlistedProducts;
      //this.wishlistedProducts = response.data.data.count.wishlistedProducts;

      //this.reqUserArray = response.data.data.interestedProducts;
     

      
    })
    .catch(function (error) {
      alert(error);
    });
  }
  ngOnInit(): void {
    if (this.router.url == '/dashboard') {
      this.dashboard = true;
    }
    this.fetchData();
  }

}
