<nz-card>
    <div class="container">
        <div class="row">
            <div class="col-7 title-text">
                <div class=" mb-5">
                    <!-- <h2>{{!ShowOffer? 'Add' : 'Update'}} Offer</h2> -->
                    <h2>Add Offer</h2>
                </div>
            </div>
            <div class="col-5 close">
                <div (click)="goBack()" class="close-icon ">
                    <i nz-icon nzType="close" nzTheme="outline" title="close"></i>
                </div>
            </div>

            <div class="col-lg-10">
                <form nz-form>

                    <nz-form-item>
                        <nz-form-label [nzLg]="6" [nzSm]="4" nzRequired>Discount Type</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-select class="w-100" [(ngModel)]="selectedType" [ngModelOptions]="{standalone: true}" nzPlaceHolder="Select Discount Type">
                                    <nz-option nzValue="By Percentage" nzLabel="By Percentage"></nz-option>
                                    <nz-option nzValue="By Price" nzLabel="By Price"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item *ngIf="selectedType == 'By Percentage'">
                        <nz-form-label [nzLg]="6" [nzSm]="4" nzRequired nzFor="ProductName">Discount Percentage</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <input type="text" nz-input placeholder="Enter Product Name">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item *ngIf="selectedType == 'By Price'">
                        <nz-form-label [nzLg]="6" [nzSm]="4" nzRequired nzFor="ProductName">Discount Price</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <input type="text" nz-input placeholder="Enter Product Name">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzLg]="6" [nzSm]="4" nzRequired nzFor="ProductImage">Banner Image</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <button type="button" nz-button nzType="primary" (click)="showMedia()">
                                    <span>Upload</span>
                                </button>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzLg]="6" [nzSm]="4" nzRequired nzFor="ProductImage">Start Date</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-date-picker [nzFormat]="dateFormat"></nz-date-picker>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzLg]="6" [nzSm]="4" nzRequired nzFor="ProductImage">End Date</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-date-picker [nzFormat]="dateFormat"></nz-date-picker>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>


                    <nz-form-item class="text-center">
                        <nz-form-control>
                            <button type="reset" class="mr-5" nz-button nzType="default">Reset</button>
                            <a><button  type="button" nz-button nzType="primary">Add</button></a>
                        </nz-form-control>
                    </nz-form-item>

                </form>

            </div>
        </div>
    </div>
</nz-card>



<!-- MODAL CONTENT ENDS -->

<nz-modal [nzStyle]="{top : '5px'}" [(nzVisible)]="MediaVisible" nzTitle="Specification" (nzOnCancel)="handleCancel()" [nzOkLoading]="isOkLoading">
    <div class="container-fluid" *nzModalContent>
        <app-media></app-media>
    </div>
</nz-modal>