<nz-card *ngIf="showTable">
    <div class="row m-b-30">
        <div class="col-lg-8">
            <div class="d-md-flex">
                <div class="m-b-10 m-r-20">
                    <nz-input-group [nzPrefix]="prefixTemplate">
                        <input type="text" nz-input placeholder="Search Project" [(ngModel)]="searchFilter">
                    </nz-input-group>
                    <ng-template #prefixTemplate>
                        <i nz-icon nzType="search" class="opacity-05"></i>
                    </ng-template>
                </div>
                <!-- <div class="m-b-10 m-r-20 d-flex align-items-center">
                    <nz-select style="min-width: 220px;" class="w-100" nzPlaceHolder="Category" [(ngModel)]="selectedCategory" (ngModelChange)="categoryChange($event)">
                        <nz-option nzLabel="All" nzValue="All"></nz-option>
                        <nz-option nzLabel="Home Decoration" nzValue="Home Decoration"></nz-option>
                        <nz-option nzLabel="Eletronic" nzValue="Eletronic"></nz-option>
                        <nz-option nzLabel="Jewellery" nzValue="Jewellery"></nz-option>
                    </nz-select>
                </div>
                <div class="m-b-10 m-r-20 d-flex align-items-center">
                    <nz-select style="min-width: 220px;" class="w-100" nzPlaceHolder="Status" [(ngModel)]="selectedStatus" (ngModelChange)="statusChange($event)">
                        <nz-option nzLabel="All" nzValue="All"></nz-option>
                        <nz-option nzLabel="In Stock" nzValue="in stock"></nz-option>
                        <nz-option nzLabel="Out Of Stock" nzValue="out of stock"></nz-option>
                    </nz-select>
                </div> -->
            </div>
        </div>
        <div class="col-lg-4 text-right add-button-section">
            <a (click)="showFormDetails()" nz-button nzType="primary">
                <i nz-icon nzType="plus-circle" theme="outline"></i>
                <span>Add Product</span>
            </a>
        </div>
    </div>
    <nz-table #productsListTable [nzData]="productsList">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzWidth]="column.width" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of productsListTable.data|filter: searchFilter;  let i=index">
                <td>{{i+1}}</td>
                <td>
                    <div class="d-flex align-items-center">
                        <!-- <nz-avatar nzShape="square" [nzSize]="60" nzIcon="picture" [nzSrc]="item.avatar"></nz-avatar> -->
                        {{item.Skuld}}
                    </div>
                </td>
                <td>{{item.name}}</td>
                <td>{{item.type}}</td>
                <td>
                    <nz-avatar nzShape="square" [nzSize]="80" nzIcon="picture" [nzSrc]="'https://seematti-backend.getdesignworks.com/'+item.image"></nz-avatar>
                </td>
                <td>{{item.bestseller}}</td>
                <td>
                    <nz-badge *ngIf="item.featured == 'featured'" nzStatus="success"></nz-badge>
                    <nz-badge *ngIf="item.featured == 'not featured'" nzStatus="error"></nz-badge>
                    <span class="text-capitalize">{{item.featured}}</span>
                </td>
                <td>
                    <nz-badge *ngIf="item.active == 'active'" nzStatus="success"></nz-badge>
                    <nz-badge *ngIf="item.active == 'inactive'" nzStatus="error"></nz-badge>
                    <span class="text-capitalize">{{item.active}}</span>
                </td>
                <!-- <td>{{item.brand}}</td> -->
                <!-- <td>{{item.modelNumber}}</td> -->
                <td>{{item.regularPrice}}</td>
                <td>{{item.salePrice}}</td>
                <!-- <td>{{item.tags}}</td> -->
                <td>{{item.category}}</td>
                <!-- <td>{{item.specification}}</td> -->

                <td class="">
                    <a class="m-r-5" nz-button nzType="default" (click)="editProduct(item)" nzShape="circle" nz-tooltip nzTooltipTitle="Edit">
                        <i nz-icon nzType="edit" theme="outline"></i>
                    </a>
                    <!-- <button nz-button nzType="default" nzShape="circle" nz-tooltip nzTooltipTitle="Delete">
                        <i nz-icon nzType="delete" theme="outline"></i>
                    </button> -->
                </td>

                <!-- <td>
                    <button (click)="AddOffer()" nz-button>{{!ShowOffer? 'Add to' : 'Update'}} Offer</button>
                </td> -->
            </tr>
        </tbody>
    </nz-table>
</nz-card>

<!-- (ngModelChange)="search()" Goes to line 7 in top -->

<!-- ADD PRODUCTS FORM DESIGN STARTS  -->

<div *ngIf="showForm" class="container">
    <div class="row">
        <div class="col-7 title-text">
            <div class=" mb-5">
                <h2 *ngIf="defaultMode == 'Add'">Add New Product</h2>
                <h2 *ngIf="defaultMode == 'Edit'">Edit Product</h2>
            </div>
        </div>
        <div class="col-5 close">
            <div class="close-icon ">
                <i (click)="showFormDetails()" nz-icon nzType="close" nzTheme="outline" title="close"></i>
            </div>
        </div>

        <div class="col-lg-10">
            <form [formGroup]="formDetails" nz-form>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductType">Type</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select id="ProductType" [(ngModel)]="productType" nzShowSearch class="w-100" nzPlaceHolder="simple">
                                <nz-option nzValue="simple" nzLabel="simple"></nz-option>
                                <nz-option nzValue="variable" nzLabel="variable"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductName">Name</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text" [(ngModel)]="productName" nz-input id="ProductName" placeholder="Enter Product Name">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductSKUID">SKU ID</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text" [(ngModel)]="productSkuID" nz-input id="ProductSKUID" placeholder="Enter SKU ID">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductImage">Image</nz-form-label>
                    <nz-form-control nzErrorTip="Please input your username!">
                        <nz-input-group>
                            <button type="button" nz-button nzType="primary" (click)="showMedia()">
                                <span>Upload</span>
                            </button>
                            <img style="padding: 5px;" *ngFor="let image of productImages" width="60" [src]="'https://seematti-backend.getdesignworks.com/'+image.image.url">
                            <!-- <p style="padding-top: 10px;">{{productImages.length}} Images Selected</p> -->
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <!-- <nz-form-item>  ../../../assets/images/logo/oasis logo.png
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductTags">Tags</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select nzMode="tags" [(ngModel)]="productTags" id="ProductTags" nzShowSearch class="w-100" nzPlaceHolder="Enter Your Tags">
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item> -->

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="BrandCategory">Select Category</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select nzMode="multiple" [(ngModel)]="categoryList" id="BrandCategory" nzShowSearch class="w-100" nzPlaceHolder="None">
                                <nz-option *ngFor="let column of listOfCategory" nzValue="{{column._id}}" nzLabel="{{column.name}}"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <!-- <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired>Specification</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <button type="button" nz-button nzType="primary" (click)="showModal()">
                                <span>Browse</span>
                            </button>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item> -->

                <!-- <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="Brand">Select Sizes</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select nzMode="multiple" id="Brand" [(ngModel)]="selectedSize" nzShowSearch class="w-100" nzPlaceHolder="select size">
                                <nz-option *ngFor="let attribute of sizeChart" nzValue="{{attribute}}" nzLabel="{{attribute}}"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item> -->

                <div class="colorPicker-select">
                    <nz-form-item class="color-selection">
                        <nz-form-label [nzSm]="4" nzRequired nzFor="attributeValue">Gradient 1</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <!-- <nz-select [(ngModel)]="SelectedColor" nzMode="tags" id="" nzShowSearch class="w-100" nzPlaceHolder="#13da3d">
                                </nz-select> -->
                                <input type="text" [(ngModel)]="color1" nz-input placeholder="#13da3d">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <input class="colorPicker-button" [(colorPicker)]="color" [style.background]="color" />
                </div>

                <div class="colorPicker-select">
                    <nz-form-item class="color-selection">
                        <nz-form-label [nzSm]="4" nzRequired nzFor="attributeValue">Gradient 2</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <!-- <nz-select [(ngModel)]="SelectedColor" nzMode="tags" id="" nzShowSearch class="w-100" nzPlaceHolder="#13da3d">
                                </nz-select> -->
                                <input type="text" [(ngModel)]="color2" nz-input placeholder="#13da3d">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <input class="colorPicker-button" [(colorPicker)]="color" [style.background]="color" />
                </div>

                <!-- <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductModelNumber">Model Number</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text" [(ngModel)]="modelNo" nz-input id="ProductModelNumber" placeholder="Enter Model Number">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item> -->

                <nz-form-item *ngIf="productType != 'variable'">
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductRegularPrice">Regular Price</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text" [(ngModel)]="regularprice" nz-input id="ProductRegularPrice" placeholder="Enter Regular Price">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="productType != 'variable'">
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductSalePrice">Sale Price</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text" [(ngModel)]="sellPrice" nz-input id="ProductSalePrice" placeholder="Enter Sale Price">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductBestSeller">Best Seller</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-switch id="ProductBestSeller" [(ngModel)]="isBestSeller" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                        </nz-input-group>
                    </nz-form-control>

                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductFeature">Feature</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-switch id="ProductFeature" [(ngModel)]="isFeatured" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                        </nz-input-group>
                    </nz-form-control>

                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductActive">Active</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-switch id="ProductActive" [(ngModel)]="isActive" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <div *ngIf="productType == 'variable'" class=" mb-5 mt-5">
                    <div class="text-center mb-3">
                        <h2>Product Attribute</h2>
                    </div>


                    <!-- <nz-form-item>
                        <nz-form-label [nzSm]="4" nzRequired nzFor="ProductSelect">Select Attribute</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-select nzMode="multiple" [(ngModel)]="selectedAttribute" id="ProductSelect" nzShowSearch class="w-100" nzPlaceHolder="None">
                                    <nz-option *ngFor="let column of attributeList" nzValue="{{column}}" nzLabel="{{column.name}}"></nz-option>
                                   
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item> -->
                    <nz-form-item>
                        <nz-form-label [nzSm]="4" nzRequired nzFor="BrandCategory">Select Attribute</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-select nzMode="multiple" [(ngModel)]="selectedAttribute" (ngModelChange)="onAttributeSelected($event)" id="BrandCategory" nzShowSearch class="w-100" nzPlaceHolder="None">
                                    <nz-option *ngFor="let column of attributeList" nzValue="{{column._id}}" nzLabel="{{column.name}}"></nz-option>
                                </nz-select>
                            </nz-input-group>

                        </nz-form-control>
                    </nz-form-item>



                    <nz-form-item *ngFor="let attributes of reqList">
                        <nz-form-label [nzSm]="4" nzRequired nzFor="ProductColor">{{attributes.name}}</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-select nzMode="multiple" id="ProductColor" [(ngModel)]="attributes.selectedValues" (ngModelChange)="onAttributeValueSelected($event, attributes.name)" nzShowSearch class="w-100" nzPlaceHolder="Red">
                                    <nz-option *ngFor="let attrValues of attributes.values" nzValue={{attrValues}} nzLabel={{attrValues}}></nz-option>
                                    <!-- <nz-option nzValue="Blue" nzLabel="Blue"></nz-option>
                                    <nz-option nzValue="yellow" nzLabel="yellow"></nz-option> -->
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" nzRequired nzFor="BrandCategory">Attribute Source</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-select [(ngModel)]="AttributeType" (ngModelChange)="selectedAttributeType()" nzShowSearch class="w-100" nzPlaceHolder="Select Attribute Type">
                                    <nz-option nzValue="image" nzLabel="Image"></nz-option>
                                    <nz-option nzValue="color" nzLabel="Color code"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <!-- <nz-form-item>
                        <nz-form-label [nzSm]="4" nzRequired nzFor="ProductSize">Size</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-select id="ProductSize" nzShowSearch class="w-100" nzPlaceHolder="XL">
                                    <nz-option nzValue="S" nzLabel="S"></nz-option>
                                    <nz-option nzValue="M" nzLabel="M"></nz-option>
                                    <nz-option nzValue="L" nzLabel="L"></nz-option>
                                    <nz-option nzValue="XL" nzLabel="XL"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item> -->

                    <!-- <nz-form-item>
                        <nz-form-label [nzSm]="4" nzRequired nzFor="ProductSize">Size</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-select id="ProductSize" nzShowSearch class="w-100" nzPlaceHolder="XL">
                                    <nz-option nzValue="S" nzLabel="S"></nz-option>
                                    <nz-option nzValue="M" nzLabel="M"></nz-option>
                                    <nz-option nzValue="L" nzLabel="L"></nz-option>
                                    <nz-option nzValue="XL" nzLabel="XL"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item> -->

                    <nz-form-item class="text-center">
                        <nz-form-control>
                            <button type="button" (click)="addVariation()" nz-button nzType="primary">Add Variation</button>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-table [nzData]="VariationData">
                        <thead>
                            <tr>
                                <th [nzWidth]="title.width" *ngFor="let title of VariationHeading">{{ title.heading }}</th>
                                <th nzWidth="150px">{{AttributeType == 'image' ? 'Select Image' : 'Select Color'}}</th>
                                <th nzWidth="150px">{{AttributeType == 'image' ? 'Photo' : 'Color code'}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of reqAttrArray; index as i">
                                <td>{{item.combination}}</td>
                                <td><input type="text" [(ngModel)]="item.skuId" nz-input id="ProductSalePrice" placeholder="Enter SKU ID"></td>
                                <td><input type="number" [(ngModel)]="item.regularPrice" nz-input id="ProductSalePrice" placeholder="Enter Regular Price"></td>
                                <td><input type="number" [(ngModel)]="item.salePrice" nz-input id="ProductSalePrice" placeholder="Enter Sale Price"></td>
                                <td *ngIf="AttributeType == 'image'"><button type="button" (click)="openModel(i)" class="mr-5" nz-button nzType="primary">Choose Image</button></td>
                                <td *ngIf="AttributeType == 'image'"><img [width]="40" [src]="'https://seematti-backend.getdesignworks.com/'+item.image"></td>
                                <td *ngIf="AttributeType == 'color'"><input class="colorPicker-button" [(colorPicker)]="color" [style.background]="color" /></td>
                                <td *ngIf="AttributeType == 'color'"><input [(ngModel)]="item.colorCode" type="text" nz-input id="" placeholder="Enter Color Code"></td>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
                <!-- <div class="text-center mb-5 mt-5 ">
                    <h2>Product Description</h2>
                </div> -->

                <!-- <nz-form-item>
                    <nz-form-label [nzSm]="4 " nzRequired nzFor="ProductShortDescription ">Short Description</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <textarea id="ProductShortDescription" [(ngModel)]="productShortDescription" nz-input placeholder="Enter Your Text Here " [nzAutosize]="{ minRows: 3, maxRows: 6 } "></textarea>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item> -->

                <!-- <nz-form-item>
                    <nz-form-label [nzSm]="4 " nzRequired nzFor="ProductLongDescription ">Long Description</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <textarea id="ProductLongDescription" [(ngModel)]="productLongDescription" nz-input placeholder="Enter Your Text Here " [nzAutosize]="{ minRows: 3, maxRows: 6 } "></textarea>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item> -->

                <nz-form-item class="text-center">
                    <nz-form-control>
                        <button (click)="alertBox()" type="reset" class="mr-5" nz-button nzType="default">Cancel</button>
                        <a><button *ngIf="defaultMode == 'Add'" (click)="doChanges()" type="button" nz-button nzType="primary">Create</button></a>
                        <a><button *ngIf="defaultMode == 'Edit'" (click)="doChanges()" type="button" nz-button nzType="primary">Save Changes</button></a>
                    </nz-form-control>
                </nz-form-item>

            </form>

        </div>
    </div>
</div>

<!-- ADD PRODUCTS FORM DESIGN ENDS  -->

<!-- MODAL CONTENT STARTS -->


<nz-modal [nzStyle]="{top : '10px'}" nzWidth="1200px" [(nzVisible)]="isVisible" nzTitle="Specification" (nzOnCancel)="handleCancel()" [nzOkLoading]="isOkLoading">
    <div class="container-fluid" *nzModalContent>
        <div class="row d-flex justify-content-between">
            <div class="col-4 text-center group-coloumn">
                <!-- <h2>Specification Group</h2> -->
                <div *ngFor="let values of specificationGroup" class="mb-3">
                    <button [ngClass]="{'SelectedButton': selectedTab == values.specGroupName}" (click)="ShowSpecValues(values.specGroupName)" nz-button nzType="default">
                        <span>{{values.specGroupName}}</span>
                    </button>
                </div>
            </div>
            <div class="col-7 spec-values-section">
                <div *ngFor="let value of specificationGroup" class="">
                    <div class="" *ngIf="selectedTab == value.specGroupName">
                        <form nz-form>
                            <div class="heading-text">
                                <h5>{{value.specGroupName}}</h5>
                            </div>
                            <nz-form-item>
                                <nz-form-label [nzSm]="4" nzRequired>RAM</nz-form-label>
                                <nz-form-control>
                                    <nz-input-group>
                                        <nz-select nzShowSearch class="w-100" nzPlaceHolder="4GB">
                                            <nz-option nzValue="2GB" nzLabel="2GB"></nz-option>
                                            <nz-option nzValue="4GB" nzLabel="4GB"></nz-option>
                                            <nz-option nzValue="6GB" nzLabel="6GB"></nz-option>
                                            <nz-option nzValue="8GB" nzLabel="8GB"></nz-option>
                                        </nz-select>
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-label [nzSm]="4" nzRequired nzFor="ProductSKUID">SKU ID</nz-form-label>
                                <nz-form-control>
                                    <nz-input-group>
                                        <input type="text" nz-input id="ProductSKUID" placeholder="Enter SKU ID">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-label [nzSm]="4" nzRequired>RAM</nz-form-label>
                                <nz-form-control>
                                    <nz-input-group>
                                        <nz-select nzShowSearch class="w-100" nzPlaceHolder="4GB">
                                            <nz-option nzValue="2GB" nzLabel="2GB"></nz-option>
                                            <nz-option nzValue="4GB" nzLabel="4GB"></nz-option>
                                            <nz-option nzValue="6GB" nzLabel="6GB"></nz-option>
                                            <nz-option nzValue="8GB" nzLabel="8GB"></nz-option>
                                        </nz-select>
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-label [nzSm]="4" nzRequired nzFor="ProductSKUID">SKU ID</nz-form-label>
                                <nz-form-control>
                                    <nz-input-group>
                                        <input type="text" nz-input id="ProductSKUID" placeholder="Enter SKU ID">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-label [nzSm]="4" nzRequired>RAM</nz-form-label>
                                <nz-form-control>
                                    <nz-input-group>
                                        <nz-select nzShowSearch class="w-100" nzPlaceHolder="4GB">
                                            <nz-option nzValue="2GB" nzLabel="2GB"></nz-option>
                                            <nz-option nzValue="4GB" nzLabel="4GB"></nz-option>
                                            <nz-option nzValue="6GB" nzLabel="6GB"></nz-option>
                                            <nz-option nzValue="8GB" nzLabel="8GB"></nz-option>
                                        </nz-select>
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-label [nzSm]="4" nzRequired nzFor="ProductSKUID">SKU ID</nz-form-label>
                                <nz-form-control>
                                    <nz-input-group>
                                        <input type="text" nz-input id="ProductSKUID" placeholder="Enter SKU ID">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-label [nzSm]="4" nzRequired>RAM</nz-form-label>
                                <nz-form-control>
                                    <nz-input-group>
                                        <nz-select nzShowSearch class="w-100" nzPlaceHolder="4GB">
                                            <nz-option nzValue="2GB" nzLabel="2GB"></nz-option>
                                            <nz-option nzValue="4GB" nzLabel="4GB"></nz-option>
                                            <nz-option nzValue="6GB" nzLabel="6GB"></nz-option>
                                            <nz-option nzValue="8GB" nzLabel="8GB"></nz-option>
                                        </nz-select>
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-label [nzSm]="4" nzRequired nzFor="ProductSKUID">SKU ID</nz-form-label>
                                <nz-form-control>
                                    <nz-input-group>
                                        <input type="text" nz-input id="ProductSKUID" placeholder="Enter SKU ID">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-label [nzSm]="4" nzRequired>RAM</nz-form-label>
                                <nz-form-control>
                                    <nz-input-group>
                                        <nz-select nzShowSearch class="w-100" nzPlaceHolder="4GB">
                                            <nz-option nzValue="2GB" nzLabel="2GB"></nz-option>
                                            <nz-option nzValue="4GB" nzLabel="4GB"></nz-option>
                                            <nz-option nzValue="6GB" nzLabel="6GB"></nz-option>
                                            <nz-option nzValue="8GB" nzLabel="8GB"></nz-option>
                                        </nz-select>
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-label [nzSm]="4" nzRequired nzFor="ProductSKUID">SKU ID</nz-form-label>
                                <nz-form-control>
                                    <nz-input-group>
                                        <input type="text" nz-input id="ProductSKUID" placeholder="Enter SKU ID">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nz-modal>


<!-- MODAL CONTENT ENDS -->

<nz-modal [nzStyle]="{top : '5px'}" nzWidth="1200px" [(nzVisible)]="MediaVisible" nzTitle="" (nzOnCancel)="handleCancel()" (nzOnOk)="sendMedia()" [nzOkLoading]="isOkLoading">
    <div class="container-fluid media-modal-section" *nzModalContent>
        <app-media></app-media>
    </div>
</nz-modal>

<!-- (nzOnOk)="handleOk()" GOES IN LINE 368 -->

<!-- ATTRIBUTE MEDIA MODAL -->

<nz-modal [nzStyle]="{top : '5px'}" nzWidth="1200px" [(nzVisible)]="AttributeMediaVisible" nzTitle="" (nzOnCancel)="handleCancel()" (nzOnOk)="getImageId()" [nzOkLoading]="isOkLoading">
    <div class="container-fluid media-modal-section" *nzModalContent>
        <app-media></app-media>
    </div>
</nz-modal>

<!-- ALERT MODAL -->

<nz-modal [(nzVisible)]="confirmAlert" nzWidth="400px" nzOkText="Yes" nzCancelText="No">
</nz-modal>