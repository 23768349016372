import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-add-attribute-forms',
  templateUrl: './add-attribute-forms.component.html',
  styleUrls: ['./add-attribute-forms.component.css']
})
export class AddAttributeFormsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
   // alert('hitted');
    // axios.get('https://oasis.getdesignworks.com/attributes', {
     
    // })
    // .then( (response) => {
    //   debugger;

    //   console.log(response.data);
     
     
    //   //console.log(response.data.status);
     
    // })
    // .catch(function (error) {
    //  alert(error.response.data.message);
    // });
  }

}
