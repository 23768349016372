import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { scrollToTop } from '../../shared/dummy';


interface DataItem {
  name: string;
  email: any;
  role: string;
  contact: any;
}

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
})
export class UserManagementComponent implements OnInit {
  selectedCategory: string;
  selectedStatus: string;
  searchInput: any;
  displayData = [];

  showTable: boolean = true;
  showForm: boolean = false;

  validateForm: FormGroup;

  orderColumn = [
    {
      title: 'S.NO',
    },
    {
      title: 'Name',
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
    },
    {
      title: 'Role ',
      compare: (a: DataItem, b: DataItem) => a.role.localeCompare(b.role),
    },
    {
      title: 'Email',
      compare: (a: DataItem, b: DataItem) => a.email.localeCompare(b.email),
    },
    {
      title: 'Mobile Number',
      compare: (a: DataItem, b: DataItem) => a.contact - b.contact,
    },
    {
      title: 'Action',
    },
  ];

  productsList = [
    {
      name: 'Erin Gonzales',
      email:'dummey@gmail.com',
      role:'',
      contact:'1234567890'
    },
    {
      name: 'Darryl ',
      email:'dummey@gmail.com',
      role:'',
      contact:'1234567891'
    },
    {
      name: 'Gonzales',
      email:'dummey@gmail.com',
      role:'',
      contact:'1234567892'
    },
    {
      name: 'Darryl',
      email:'dummey@gmail.com',
      role:'',
      contact:'1234567890'
    },
    {
      name: 'Gonzales',
      email:'dummey@gmail.com',
      role:'',
      contact:'1234567890'
    },
    {
      name: 'Erin',
      email:'dummey@gmail.com',
      role:'',
      contact:'1234567890'
    },
    {
      name: 'Darryl',
      email:'dummey@gmail.com',
      role:'',
      contact:'1234567890'
    },
  ];

  // constructor(private tableSvc : TableService) {
  //     this.displayData = this.productsList
  // }

  // search(): void {
  //     const data = this.productsList
  //     this.displayData = this.tableSvc.search(this.searchInput, data )
  // }
  constructor(private fb: FormBuilder){}

  categoryChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.name === value))
    this.displayData = data;
  }

  statusChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.feature === value))
    //   : (this.displayData = data);
    this.displayData = data;
  }

  ngOnInit(): void {
    scrollToTop();
    this.validateForm = this.fb.group({});
  }

  showFormDetails() {
    this.showTable = !this.showTable;
    this.showForm = !this.showForm;
  }

  // FORM RESET SECTION

  resetForm(): void {
    this.validateForm.reset();
  }
}
