<div class="container">
    <div class="row">
        <div class="col-10">
            <div class="text-center mb-5">
                <h2>Add New Product</h2>
            </div>
            <form nz-form>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductType">Type</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select id="ProductType" nzShowSearch class="w-100" nzPlaceHolder="Simple">
                                <nz-option nzValue="Simple" nzLabel="Simple"></nz-option>
                                <nz-option nzValue="Variable" nzLabel="Variable"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductName">Name</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text" nz-input id="ProductName" placeholder="Enter Category Name">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductSKUID">SKU ID</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text" nz-input id="ProductSKUID" placeholder="Enter SKU ID">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductImage">Image</nz-form-label>
                    <nz-form-control nzErrorTip="Please input your username!">
                        <nz-input-group>
                            <button nz-button nzType="default">
                                <span>Upload</span>
                            </button>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductTags">Tags</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select nzMode="tags" id="ProductTags" nzShowSearch class="w-100" nzPlaceHolder="Enter Your Tags">
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="BrandSpecification">Specification</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select nzMode="multiple" id="BrandSpecification" nzShowSearch class="w-100" nzPlaceHolder="None">
                                <nz-option nzValue="Processor Specification" nzLabel="Processor Specification"></nz-option>
                                <nz-option nzValue="Cooling System Specification" nzLabel="Cooling System Specification"></nz-option>
                                <nz-option nzValue="Mother Board Specification" nzLabel="Mother Board Specification"></nz-option>
                                <nz-option nzValue="Graphics Card Specification" nzLabel="Graphics Card Specification"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="Brand">Brand</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select id="Brand" nzShowSearch class="w-100" nzPlaceHolder="AMD">
                                <nz-option nzValue="AMD" nzLabel="AMD"></nz-option>
                                <nz-option nzValue="ASUS" nzLabel="ASUS"></nz-option>
                                <nz-option nzValue="Deep Cool" nzLabel="Deep Cool"></nz-option>
                                <nz-option nzValue="BenQ" nzLabel="BenQ"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductModelNumber">Model Number</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text" nz-input id="ProductModelNumber" placeholder="Enter Model Number">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductRegularPrice">Regular Price</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text" nz-input id="ProductRegularPrice" placeholder="Enter Regular Price">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductSalePrice">Sale Price</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text" nz-input id="ProductSalePrice" placeholder="Enter Sale Price">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductBestSeller">Best Seller</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-switch id="ProductBestSeller" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                        </nz-input-group>
                    </nz-form-control>

                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductFeature">Feature</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-switch id="ProductFeature" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                        </nz-input-group>
                    </nz-form-control>

                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductActive">Active</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-switch id="ProductActive" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <div class="text-center mb-5 mt-5">
                    <h2>Product Attribute</h2>
                </div>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductSelect">Select Attribute</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select id="ProductSelect" nzShowSearch class="w-100" nzPlaceHolder="None">
                                <nz-option nzValue="Color" nzLabel="Color"></nz-option>
                                <nz-option nzValue="Size" nzLabel="Size"></nz-option>
                                <nz-option nzValue="" nzLabel=""></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductColor">Color</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select id="ProductColor" nzShowSearch class="w-100" nzPlaceHolder="Red">
                                <nz-option nzValue="Red" nzLabel="Red"></nz-option>
                                <nz-option nzValue="Blue" nzLabel="Blue"></nz-option>
                                <nz-option nzValue="yellow" nzLabel="yellow"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductSize">Size</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select id="ProductSize" nzShowSearch class="w-100" nzPlaceHolder="XL">
                                <nz-option nzValue="S" nzLabel="S"></nz-option>
                                <nz-option nzValue="M" nzLabel="M"></nz-option>
                                <nz-option nzValue="L" nzLabel="L"></nz-option>
                                <nz-option nzValue="XL" nzLabel="XL"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="ProductSize">Size</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select id="ProductSize" nzShowSearch class="w-100" nzPlaceHolder="XL">
                                <nz-option nzValue="S" nzLabel="S"></nz-option>
                                <nz-option nzValue="M" nzLabel="M"></nz-option>
                                <nz-option nzValue="L" nzLabel="L"></nz-option>
                                <nz-option nzValue="XL" nzLabel="XL"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="text-center">
                    <nz-form-control>
                        <button nz-button nzType="primary">Add Variation</button>
                    </nz-form-control>
                </nz-form-item>

                <nz-table [nzData]="VariationData">
                    <thead>
                        <tr>
                            <th *ngFor="let title of VariationHeading">{{ title.heading }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of VariationData">
                            <td>{{item.Variation}}</td>
                            <td>{{item.RegularPrice}}</td>
                            <td>{{item.SKU}}</td>
                            <td>{{item.SalePrice}}</td>
                            <td>{{item.Photo}}</td>
                        </tr>
                    </tbody>
                </nz-table>

                <div class="text-center mb-5 mt-5 ">
                    <h2>Product Description</h2>
                </div>

                <nz-form-item>
                    <nz-form-label [nzSm]="4 " nzRequired nzFor="ProductShortDescription ">Short Description</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <textarea id="ProductShortDescription " nz-input placeholder="Enter Your Text Here " [nzAutosize]="{ minRows: 3, maxRows: 6 } "></textarea>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4 " nzRequired nzFor="ProductLongDescription ">Long Description</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <textarea id="ProductLongDescription " nz-input placeholder="Enter Your Text Here " [nzAutosize]="{ minRows: 3, maxRows: 6 } "></textarea>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="text-center">
                    <nz-form-control>
                        <button class="mr-5 " nz-button nzType="default">Reset</button>
                        <a routerLink="/products"><button nz-button nzType="primary">Add</button></a>
                    </nz-form-control>
                </nz-form-item>

            </form>

        </div>
    </div>
</div>