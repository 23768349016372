import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { scrollToTop } from '../../shared/dummy';


interface DataItem {
  type: any;
  typeId: any;
  banner: any;
  active: any;
  location: any;
  createdBy: any;
  createdAt: any;
  lastUpdateBy: any;
  lastUpdateAt: any;
}

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.css'],
})
export class BannersComponent implements OnInit {
  selectedCategory: string;
  selectedStatus: string;
  searchInput: any;
  displayData = [];

  showTable: boolean = true;
  showForm: boolean = false;

  MediaVisible = false;
  isOkLoading = false;

  validateForm:FormGroup

  orderColumn = [
    {
      title: 'S.NO',
    },
    {
      title: 'Type',
      compare: (a: DataItem, b: DataItem) => a.type - b.type,
    },
    {
      title: 'Type Id',
      compare: (a: DataItem, b: DataItem) => a.typeId - b.typeId,
    },
    {
      title: 'Banner',
      compare: (a: DataItem, b: DataItem) => a.banner - b.banner,
    },
    {
      title: 'Active',
      compare: (a: DataItem, b: DataItem) => a.active - b.active,
    },
    {
      title: 'Location',
      compare: (a: DataItem, b: DataItem) => a.location - b.location,
    },
    {
      title: 'Created By',
      compare: (a: DataItem, b: DataItem) => a.createdBy - b.createdBy,
    },
    {
      title: 'Created At',
      compare: (a: DataItem, b: DataItem) => a.createdAt - b.createdAt,
    },
    {
      title: 'Last Update By',
      compare: (a: DataItem, b: DataItem) => a.lastUpdateBy - b.lastUpdateBy,
    },
    {
      title: 'Last Update At',
      compare: (a: DataItem, b: DataItem) => a.lastUpdateAt - b.lastUpdateAt,
    },

    {
      title: 'Action',
    },
  ];

  productsList = [
    {
      type: 'brand',
      typeId: '124',
      banner: 'assets/images/others/thumb-10.jpg',
      active: 'active',
      location: 'banner carousel',
      createdBy: 'user1',
      createdAt: 'user2',
      lastUpdateBy: 'user3',
      lastUpdateAt: 'user4',
    },
  ];

  // constructor(private tableSvc : TableService) {
  //     this.displayData = this.productsList
  // }

  // search(): void {
  //     const data = this.productsList
  //     this.displayData = this.tableSvc.search(this.searchInput, data )
  // }

  constructor(private fb: FormBuilder){}

  categoryChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.name === value))
    this.displayData = data;
  }

  statusChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.feature === value))
    //   : (this.displayData = data);
    this.displayData = data;
  }

  ngOnInit(): void {
    scrollToTop();
    this.validateForm = this.fb.group({});
  }

  showFormDetails() {
    this.showTable = !this.showTable;
    this.showForm = !this.showForm;
  }

   // MODEL SECTION
   showMedia() {
    this.MediaVisible = true;
  }
  handleCancel(): void {
    this.MediaVisible = false;
  }

  // FORM RESET SECTION

  resetForm(): void {
    this.validateForm.reset();
  }
}
