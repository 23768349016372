import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { scrollToTop } from '../../shared/dummy';
import axios, { Axios } from 'axios';


interface DataItem {
  id: string;
  name: string;
  brand: any;
  address1: any;
  address2: any;
  state: any;
  city: any;
  pincode: number;
  map: any;
  phone: any;
  landline: any;
  description: any;
  website: any;
  active: any;
}

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {

  selectedCategory: string;
  selectedStatus: string;
  searchInput: any;
  displayData = [];
  brands = [];

  centerName = '';
  brandList = [];
  address1 = '';
  address2 = '';
  city = '';
  state = '';
  pincode = '';
  mapUrl = '';
  mobNo = '';
  landlineNo = '';
  description = '';
  website = '';
  isActive = '';

  showTable: boolean = true;
  showForm: boolean = false;

  validateForm: FormGroup;

  fetchBrand(){
    axios.get('https://oasis.getdesignworks.com/brands', {
      headers: {
            
        Authorization: 'Bearer ' + localStorage.getItem('Token'),
      },
    })
    .then( (response) => { 
      console.log(response.data.data);
       this.brands = response.data.data;
     
     
      //console.log(response.data.status);
     
    })
    .catch(function (error) {
      console.log(error);
     //alert(error.response.data.message);
    });
  }

  fetchData(){
    axios.post('https://oasis.getdesignworks.com/service-center/getAll', {
      headers: {
            
        Authorization: 'Bearer ' + localStorage.getItem('Token'),
      },
    })
    .then( (response) => { 
      console.log(response.data.data);
       this.productsList = response.data.data.serviceCenters.map((data:any,index: number) => {
         return {
           id: data._id,
          name: data.name,
          brand: data.brands,
          address1: data.serviceLocation.addressLine1,
          address2: data.serviceLocation.addressLine12,
          state: data.serviceLocation.state,
          city: data.serviceLocation.city,
          pincode: data.serviceLocation.pincode,
          map: data.map,
          phone: data.phone,
          landline: data.landline,
          description: data.description,
          website: data.website,
          active: data.isActive
         }
        });
     
     this.fetchBrand();
      //console.log(response.data.status);
     
    })
    .catch(function (error) {
      console.log(error);
     //alert(error.response.data.message);
    });
  }

  orderColumn = [
    {
      title: 'S.NO',
    },
    {
      title: 'Name',
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
    },
    {
      title: 'Brand',
      compare: (a: DataItem, b: DataItem) => a.brand - b.brand,
    },
    {
      title: 'Address 1',
      compare: (a: DataItem, b: DataItem) => a.address1 - b.address1,
    },

    {
      title: 'Address 2',
      compare: (a: DataItem, b: DataItem) => a.address2 - b.address2,
    },
    {
      title: 'State',
      compare: (a: DataItem, b: DataItem) => a.state - b.state,
    },
    {
      title: 'City',
      compare: (a: DataItem, b: DataItem) => a.city - b.city,
    },
    {
      title: 'Pincode',
      compare: (a: DataItem, b: DataItem) => a.pincode - b.pincode,
    },
    {
      title: 'Map',
      compare: (a: DataItem, b: DataItem) => a.map - b.map,
    },
    {
      title: 'Phone',
      compare: (a: DataItem, b: DataItem) => a.phone - b.phone,
    },
    {
      title: 'Landline',
      compare: (a: DataItem, b: DataItem) => a.landline - b.landline,
    },
    {
      title: 'Description',
      compare: (a: DataItem, b: DataItem) => a.description - b.description,
    },
    {
      title: 'Website',
      compare: (a: DataItem, b: DataItem) => a.website - b.website,
    },
    {
      title: 'Active',
      compare: (a: DataItem, b: DataItem) => a.active - b.active,
    },
    {
      title: 'Action',
    },
  ];
  productsList : DataItem [] = [];
  // productsList = [
  //   {
  //       name: 'SKY PRO',
  //       brand: 'HP',
  //       address1: 'NO 11 , west street',
  //       address2: 'no12 , main road',
  //       state: 'tamil nadu',
  //       city: 'nagapattinam',
  //       pincode: '611105',
  //       map: 'sdfghksdifdgbdg',
  //       phone: '1213123121',
  //       landline: '14366859878',
  //       description: 'helo',
  //       website: 'www.skypro.com',
  //       active: 'active',
  //     },
    
    
  // ];

  // constructor(private tableSvc : TableService) {
  //     this.displayData = this.productsList
  // }

  // search(): void {
  //     const data = this.productsList
  //     this.displayData = this.tableSvc.search(this.searchInput, data )
  // }
  constructor(private fb: FormBuilder){}

  categoryChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.name === value))
    this.displayData = data;
  }

  statusChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.feature === value))
    //   : (this.displayData = data);
    this.displayData = data;
  }

  ngOnInit(): void {
    scrollToTop();
    this.fetchData();
    this.validateForm = this.fb.group({});
  }

  showFormDetails() {
    this.showTable = !this.showTable;
    this.showForm = !this.showForm;
  }

  // FORM RESET SECTION

  resetForm(): void {
    this.validateForm.reset();
  }
  doChanges(){
    var self = this;
    if(self.centerName == ''){
      alert('Enter Center Name');
    }else if(self.brandList.length == 0){
      alert('Enter Brand Names');
    }
    else if(self.address1 == ''){
      alert('Enter Address');
    } else if(self.city == ''){
      alert('Enter City Name');
    } else if(self.state == ''){
      alert('Enter State Name');
    } else if(self.pincode == ''){
      alert('Enter Pincode');
    } else {
      var serviceCenterDetails = {
        "name": self.centerName,
        "brands": self.brandList,
        "serviceLocation": {
            "addressLine1" : self.address1,
            "addressLine2" : self.address2,
            "city" : self.city,
            "state" : self.state,
            "pincode" : self.pincode
        },
        "phone": self.mobNo,
        "description": self.description,
        "website": self.website,
        "isActive": false
      }
      axios
      .post(
        'https://oasis.getdesignworks.com/service-center/create',
        serviceCenterDetails,
        {
          headers: {
            
            Authorization: 'Bearer ' + localStorage.getItem('Token'),
          },
        }
      )
      .then(function (response) {
        if (response.data.status == 'success') {
          console.log(response.data.data);
          self.fetchData();
          self.showTable = !self.showTable;
          self.showForm = !self.showForm;
         //  localStorage.setItem('Token',response.data.data);
         //  self.router.navigate(['dashboard'])
        }
      })
      .catch(function (error) {
        let errorData = error.response.data.data;
        let errorString = 'Please Check Below Error \n\n';
        if (errorData) {
          for (let i = 0; i < errorData.length; i++) {
            errorString += errorData[i].param + ' - ' + errorData[i].msg + '\n';
          }
          alert(errorString);
        }
      });
    }
  }
  editChanges(value){
alert(value.id);
  }

}
