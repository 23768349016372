import { Injectable, EventEmitter } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavService {
  navChange:EventEmitter<any> = new EventEmitter();
  public formRefreshAnnouncedSource:BehaviorSubject<any> = new BehaviorSubject<any>('');
  public formRefreshAnnouncedSource1:BehaviorSubject<any> = new BehaviorSubject<any>('');
  public attributesList:BehaviorSubject<any> = new BehaviorSubject<any>('');
  public brandList:BehaviorSubject<any> = new BehaviorSubject<any>('');
  
  constructor() { 

  }
  
  publishFormRefresh(value){
    this.formRefreshAnnouncedSource.next(value)
  }
  parentToChild(value){
      this.formRefreshAnnouncedSource1.next(value);
  }
  sendBrandList(values){
      this.brandList.next(values);
  }
  sendAttributesList(value){
    this.attributesList.next(value)
  }

 

}