<div class="container">
    <div class="row">
        <div class="col-10">
            <div class="text-center mb-5">
                <h2>Add New Banner</h2>
            </div>
            <form nz-form>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="BannerType">Type</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select id="BannerType" nzShowSearch class="w-100" nzPlaceHolder="Brand">
                                <nz-option nzValue="Product" nzLabel="Product"></nz-option>
                                <nz-option nzValue="hero-section" nzLabel="Category"></nz-option>
                                <nz-option nzValue="Brand" nzLabel="Brand"></nz-option>

                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="BannerLink">Link</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text" nz-input id="BannerLink" placeholder="Enter Link">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="BannerImage">Banner Image</nz-form-label>
                    <nz-form-control nzErrorTip="Please input your username!">
                        <nz-input-group>
                            <button nz-button nzType="default">
                                <span>Upload</span>
                            </button>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="BannerLocation">Location</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select id="BannerLocation" nzShowSearch class="w-100" nzPlaceHolder="Hero Section">
                                <nz-option nzValue="Main Carousel" nzLabel="Main Carousel"></nz-option>
                                <nz-option nzValue="Hero Section" nzLabel="Hero Section"></nz-option>
                                <nz-option nzValue="Sub Menu" nzLabel="Sub Menu"></nz-option>

                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="attributeActive">Active</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-switch id="attributeActive" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="text-center">
                    <nz-form-control>
                        <button class="mr-5" nz-button nzType="default">Reset</button>
                        <a routerLink="/banners"><button nz-button nzType="primary">Add</button></a>
                    </nz-form-control>
                </nz-form-item>

            </form>

        </div>
    </div>
</div>