import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from './layouts/full-layout/full-layout.component';
import { CommonLayoutComponent } from './layouts/common-layout/common-layout.component';

import { FullLayout_ROUTES } from './shared/routes/full-layout.routes';
import { CommonLayout_ROUTES } from './shared/routes/common-layout.routes';
import { DashboardComponent } from './project/dashboard/dashboard.component';
import { AttributesComponent } from './project/attributes/attributes.component';
import { BrandsComponent } from './project/brands/brands.component';
import { BannersComponent } from './project/banners/banners.component';
import { CategoryComponent } from './project/category/category.component';
import { MediaComponent } from './project/media/media.component';
import { ProductsComponent } from './project/products/products.component';
import { SettingComponent } from './project/setting/setting.component';
import { AddAttributeFormsComponent } from './Forms/add-attribute-forms/add-attribute-forms.component';
import { AddBrandFormsComponent } from './Forms/add-brand-forms/add-brand-forms.component';
import { AddCategoryFormsComponent } from './Forms/add-category-forms/add-category-forms.component';
import { AddProductsFormsComponent } from './Forms/add-products-forms/add-products-forms.component';
import { AddBannersFormsComponent } from './Forms/add-banners-forms/add-banners-forms.component';
import { AddServiceFormsComponent } from './Forms/add-service-forms/add-service-forms.component';
import { SpecGroupComponent } from './project/spec-group/spec-group.component';
import { SpecValueComponent } from './project/spec-value/spec-value.component';
import { AddSpecGroupComponent } from './Forms/add-spec-group/add-spec-group.component';
import { AddSpecValueComponent } from './Forms/add-spec-value/add-spec-value.component';
import { LoginComponent } from './project/login/login.component';
import { HomeComponent } from './project/home/home.component';
import { OrderDetailsComponent } from './project/order-details/order-details.component';
import { ForgotPasswordComponent } from './project/forgot-password/forgot-password.component';
import { UserManagementComponent } from './project/user-management/user-management.component';
import { OfferComponent } from './project/offer/offer.component';
import { AddToOfferPageComponent } from './project/add-to-offer-page/add-to-offer-page.component';
import { ReportComponent } from './project/report/report.component';
import { WhatsappSharedComponent } from './project/whatsapp-shared/whatsapp-shared.component';

const appRoutes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '/dashboard/e-commerce',
  //   pathMatch: 'full',
  // },
  // {
  //   path: '',
  //   component: CommonLayoutComponent,
  //   children: CommonLayout_ROUTES,
  // },
  // {
  //   path: '',
  //   component: FullLayoutComponent,
  //   children: FullLayout_ROUTES,
  // },

  // MAIN HOME PAGE

  { path: 'home', component: HomeComponent },

  { path: 'dashboard', component: DashboardComponent },
  { path: 'attribute', component: AttributesComponent },
  { path: 'brands', component: BrandsComponent },
  { path: 'banners', component: BannersComponent },
  { path: 'category', component: CategoryComponent },
  { path: 'media', component: MediaComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'setting', component: SettingComponent },
  { path: 'specGroup', component: SpecGroupComponent },
  { path: 'specValue', component: SpecValueComponent },
  { path: 'recent-orders', component: OrderDetailsComponent },
  { path: 'user-management', component: UserManagementComponent },
  { path: 'offer-page', component: OfferComponent },
  { path: 'report', component: ReportComponent },
  { path: 'add-offer', component: AddToOfferPageComponent },
  { path: 'whatsapp-shared', component: WhatsappSharedComponent },

  // FORMS ROUTING

  { path: 'addAttributes', component: AddAttributeFormsComponent },
  { path: 'addBrands', component: AddBrandFormsComponent },
  { path: 'addCategory', component: AddCategoryFormsComponent },
  { path: 'addProducts', component: AddProductsFormsComponent },
  { path: 'addBanner', component: AddBannersFormsComponent },
  { path: 'addService', component: AddServiceFormsComponent },
  { path: 'addSpecGroup', component: AddSpecGroupComponent },
  { path: 'addSpecValue', component: AddSpecValueComponent },

  // LOGIN AND SIGNUP PAGES

  { path: '', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes
      //   {
      //   preloadingStrategy: PreloadAllModules,
      //   anchorScrolling: 'enabled',
      //   scrollPositionRestoration: 'enabled',
      // }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
