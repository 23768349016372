<nz-card *ngIf="showTable">
    <div class="row m-b-30">
        <div class="col-lg-8">
            <div class="d-md-flex">
                <div class="m-b-10 m-r-20">
                    <nz-input-group [nzPrefix]="prefixTemplate">
                        <input type="text" nz-input placeholder="Search Project" [(ngModel)]="searchInput">
                    </nz-input-group>
                    <ng-template #prefixTemplate>
                        <i nz-icon nzType="search" class="opacity-05"></i>
                    </ng-template>
                </div>
                <div class="m-b-10 m-r-20 d-flex align-items-center">
                    <nz-select style="min-width: 220px;" class="w-100" nzPlaceHolder="Category" [(ngModel)]="selectedCategory" (ngModelChange)="categoryChange($event)">
                        <nz-option nzLabel="All" nzValue="All"></nz-option>
                        <nz-option nzLabel="Home Decoration" nzValue="Home Decoration"></nz-option>
                        <nz-option nzLabel="Eletronic" nzValue="Eletronic"></nz-option>
                        <nz-option nzLabel="Jewellery" nzValue="Jewellery"></nz-option>
                    </nz-select>
                </div>
                <div class="m-b-10 m-r-20 d-flex align-items-center">
                    <nz-select style="min-width: 220px;" class="w-100" nzPlaceHolder="Status" [(ngModel)]="selectedStatus" (ngModelChange)="statusChange($event)">
                        <nz-option nzLabel="All" nzValue="All"></nz-option>
                        <nz-option nzLabel="In Stock" nzValue="in stock"></nz-option>
                        <nz-option nzLabel="Out Of Stock" nzValue="out of stock"></nz-option>
                    </nz-select>
                </div>
            </div>
        </div>
        <div class="col-lg-4 text-right add-button-section">
            <a (click)="showFormDetails()" nz-button nzType="primary">
                <i nz-icon nzType="plus-circle" theme="outline"></i>
                <span>Add Banners</span>
            </a>
        </div>
    </div>
    <nz-table #productsListTable [nzData]="productsList">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of productsListTable.data;  let i=index">
                <td>{{i+1}}</td>
                <td>
                    <div class="d-flex align-items-center">
                        <!-- <nz-avatar nzShape="square" [nzSize]="60" nzIcon="picture" [nzSrc]="item.avatar"></nz-avatar> -->
                        {{item.type}}
                    </div>
                </td>
                <td>
                    {{item.typeId}}
                </td>
                <td>
                    <nz-avatar nzShape="square" [nzSize]="80" nzIcon="picture" [nzSrc]="item.banner"></nz-avatar>
                </td>
                <td>
                    <nz-badge *ngIf="item.active == 'active'" nzStatus="success"></nz-badge>
                    <nz-badge *ngIf="item.active == 'inactive'" nzStatus="error"></nz-badge>
                    <span class="text-capitalize">{{item.active}}</span>
                </td>
                <td>{{item.location}}</td>
                <td>{{item.createdBy}}</td>
                <td>{{item.createdAt}}</td>
                <td>{{item.lastUpdateBy}}</td>
                <td>{{item.lastUpdateAt}}</td>
                <td class="">
                    <a class="m-r-5" nz-button nzType="default" nzShape="circle" nz-tooltip nzTooltipTitle="Edit">
                        <i nz-icon nzType="edit" theme="outline"></i>
                    </a>
                    <!-- <button nz-button nzType="default" nzShape="circle" nz-tooltip nzTooltipTitle="Delete">
                        <i nz-icon nzType="delete" theme="outline"></i>
                    </button> -->
                </td>
            </tr>
        </tbody>
    </nz-table>
</nz-card>

<!-- (ngModelChange)="search()" Goes to line 7 in top -->

<!-- ADD BANNERS FORM DESIGN STARTS  -->

<div *ngIf="showForm" class="container">
    <div class="row">
        <div class="col-7 title-text">
            <div class=" mb-5">
                <h2>Add New Banner</h2>
            </div>
        </div>
        <div class="col-5 close">
            <div class="close-icon ">
                <i (click)="showFormDetails()" nz-icon nzType="close" nzTheme="outline" title="close"></i>
            </div>
        </div>

        <div class="col-lg-10">
            <form [formGroup]="validateForm" nz-form>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="BannerType">Type</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select id="BannerType" nzShowSearch class="w-100" nzPlaceHolder="Brand">
                                <nz-option nzValue="Product" nzLabel="Product"></nz-option>
                                <nz-option nzValue="hero-section" nzLabel="Category"></nz-option>
                                <nz-option nzValue="Brand" nzLabel="Brand"></nz-option>

                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="BannerLink">Link</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text" nz-input id="BannerLink" placeholder="Enter Link">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="BannerImage">Banner Image</nz-form-label>
                    <nz-form-control nzErrorTip="Please input your username!">
                        <nz-input-group>
                            <button type="button" nz-button nzType="default" (click)="showMedia()">
                                <span>Upload</span>
                            </button>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="BannerLocation">Location</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select id="BannerLocation" nzShowSearch class="w-100" nzPlaceHolder="Hero Section">
                                <nz-option nzValue="Main Carousel" nzLabel="Main Carousel"></nz-option>
                                <nz-option nzValue="Hero Section" nzLabel="Hero Section"></nz-option>
                                <nz-option nzValue="Sub Menu" nzLabel="Sub Menu"></nz-option>

                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="attributeActive">Active</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-switch id="attributeActive" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="text-center">
                    <nz-form-control>
                        <button (click)="resetForm()" type="reset" class="mr-5" nz-button nzType="default">Reset</button>
                        <a><button (click)="showFormDetails()" nz-button nzType="primary">Add Banner</button></a>
                    </nz-form-control>
                </nz-form-item>

            </form>

        </div>
    </div>
</div>

<!-- ADD BANNERS FORM DESIGN ENDS  -->

<!-- MEDIA MODEL SECTION STARTS -->

<nz-modal [nzStyle]="{top : '5px'}" [(nzVisible)]="MediaVisible" nzTitle="Specification" (nzOnCancel)="handleCancel()" [nzOkLoading]="isOkLoading">
    <div class="container-fluid" *nzModalContent>
        <app-media></app-media>
    </div>
</nz-modal>

<!-- MEDIA MODEL SECTION STARTS -->