<nz-card>
    <div class="row m-b-30">
        <div class="col-lg-12">
            <div class="d-md-flex" [ngClass]="{'dashboard-page' : dashboard}">
                <h3>Whatsapp Shared List</h3>
                <a class="view-all-link" *ngIf="dashboard" routerLink="/whatsapp-shared">View All</a >
            </div>
        </div>
    </div>

    <nz-table #SharedTable [nzData]="shared">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzWidth]="column.width" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of whatsappShared;  let i=index">
                <td>{{i+1}}</td>
                <td>{{item.product.name}}</td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.customerName}}
                    </div>
                </td>
                <td>
                    {{item.customerPhone}}
                </td>


                <!-- <td class="">
                    <a (click)="editAttribute(item)" class="m-r-5" nz-button nzType="default" nzShape="circle" nz-tooltip nzTooltipTitle="Edit">
                        <i nz-icon nzType="edit" theme="outline"></i>
                    </a>
                <button nz-button nzType="default" nzShape="circle" nz-tooltip nzTooltipTitle="Delete">
                        <i nz-icon nzType="delete" theme="outline"></i>
                    </button>
                </td> -->
                </tr>
                </tbody>
                </nz-table>
</nz-card>